export default [
  {
    route: 'WeeklyReviewIntro',
    hasForm: false,
  },
  {
    route: 'WeeklyReviewWeight',
    hasForm: true,
    step: ['weight'],
  },
  {
    route: 'WeeklyReviewWeightFeedback',
    feedback: 'weight',
    hasForm: false,
  },
  {
    route: 'WeeklyReviewBmi',
    feedback: 'bmi',
    hasForm: false,
  },
  {
    route: 'WeeklyReviewEating',
    hasForm: true,
    step: ['eating'],
    loading: true,
  },
  {
    route: 'WeeklyReviewEatingFeedback',
    feedback: 'eating',
    hasForm: false,
  },
  {
    route: 'WeeklyReviewEatingPlan',
    buttonText: 'Save & continue',
    hasForm: true,
    step: ['eating-plan'],
    loading: true,
  },
  {
    route: 'WeeklyReviewActivity',
    hasForm: true,
    step: ['activity-goals', 'walking'],
    loading: true,
  },
  {
    route: 'WeeklyReviewActivityFeedback',
    feedback: ['activity-goals', 'walking'],
    hasForm: false,
  },
  {
    route: 'WeeklyReviewActivityPlan',
    buttonText: 'Save & continue',
    hasForm: true,
    step: ['activity-plan'],
    loading: true,
  },
  {
    route: 'WeeklyReviewCompleted',
    hasForm: false,
  },
]
