<template>
  <plugin-article
    :img="'weekly-review/activity.png'">
      <loading-indicator
        v-if="isLoading"/>

      <template
        v-else>
          <template slot="title">
            {{ $t(`${$options.slug}.title`) }}
          </template>
          <template slot="content">
            <p>
              {{ $t(`${$options.slug}.content-1`) }}
            </p>
            <template
              v-if="hasStepGoals">
                <p>
                  {{ $t(`${$options.slug}.content-2`) }}
                </p>
                <template
                  v-if="isActivityGoalStep">
                    <base-form
                      ref="form"
                      :hasSubmitButton="false"
                      @submit="onSubmit">
                        <grid>
                            <grid-column
                              v-for="(goal, i) in form.goals"
                              :key="i"
                              xs-12
                              md-6>
                                <card-goal
                                  class="activity-goal"
                                  :answer="goal.answer"
                                  :plugin="'Activity goal'"
                                  :title="goal.title"
                                  :validation="$options.validation.goal"
                                  v-model="goal.rate"/>
                            </grid-column>
                        </grid>
                    </base-form>
                </template>
                <template
                  v-else>
                    <grid>
                      <grid-column
                        xs-12>
                          <card-goal
                            class="step-goal"
                            :hasQuestion="false"
                            :plugin="'Steps goal'"
                            :title="`${walkingGoal} steps`"/>
                      </grid-column>
                    </grid>

                    <h2 class="weekly-review-step-totals">
                      {{ $t(`${$options.slug}.step-totals`) }}
                    </h2>

                    <plugin-unit-chart>
                      <bar-chart
                        :chartType="'bar'"
                        :goal="walkingGoal"
                        :tooltip="(tooltipItem, data) => {
                          return `${data.datasets[0].data[tooltipItem.index]} steps`
                        }"
                        v-bind="{ annotationLabel, chartData }"
                      />
                  </plugin-unit-chart>
                </template>
            </template>
            <weekly-review-no-goals
              v-else
              :type="'Physical Activity'"/>
          </template>
      </template>
  </plugin-article>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import BaseForm from '@/components/base/BaseForm'
  import CardGoal from '@/components/cards/card-goal/CardGoal'
  import BarChart from '@/components/charts/bar-chart/BarChart'
  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import LoadingIndicator from '@/components/loading-indicator'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import { PluginUnitChart } from '@/components/plugin/plugin-charts'
  import WeeklyReviewNoGoals from '@/components/weekly-review/WeeklyReviewNoGoals'

  import weeklyReviewSubmit from '@/mixins/weekly-review/weeklyReviewSubmit'

  import eventBus from '@/tools/event-bus'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review-activity.meta.title'),
      }
    },

    components: {
      BarChart,
      BaseForm,
      CardGoal,
      Grid,
      GridColumn,
      LoadingIndicator,
      PluginArticle,
      PluginUnitChart,
      WeeklyReviewNoGoals,
    },

    mixins: [
      weeklyReviewSubmit,
    ],

    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    created() {
      this.fetchWeeklyReviewData()
        .then(() => { this.form.goals = [...this.activityGoals] })
    },

    mounted() {
      eventBus.$on('weeklyReview/onNext', this.onSubmit)

      this.form.step_type = this.stepsArray
        .find(step => step === 'walking' || step === 'activity-goals')
    },

    beforeDestroy() {
      eventBus.$off('weeklyReview/onNext')
    },

    data() {
      return {
        form: {
          goals: [],
          step_type: '',
        },
      }
    },

    computed: {
      ...mapGetters('stepTracking', [
        'getChartDataByTimePeriod',
      ]),
      ...mapState('weeklyReview', [
        'activityGoals',
        'stepsArray',
        'walkingGoal',
      ]),
      annotationLabel() {
        return this.$t('Daily steps goal - {stepGoal} steps', { stepGoal: this.walkingGoal })
      },
      isActivityGoalStep() {
        return this.stepsArray.includes('activity-goals')
      },
      hasStepGoals() {
        return this.form.goals.length || this.walkingGoal
      },
      chartData() {
        return this.getChartDataByTimePeriod('week')
      },
    },

    slug: 'views.plugins.weekly-review.weekly-review-activity',

    validation: {
      goal: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .weekly-review {
    &-step-totals {
      margin: 3rem 0 1.5rem;
      text-align: center;
    }
  }
</style>
