<template>
  <footer
    v-if="canFooterBeVisible"
    ref="footer"
    class="footer"
    :class="{
      'footer--is-visible': isFooterVisible,
    }"
    data-testid="footer"
  >
    <div
      class="footer__inner"
      id="footer__inner">
      <div
        class="footer__white"
        id="footer__white"
        data-testid="footer-white"
      >
        <feedback/>
        <span
          class="footer__separator"
          v-if="!isAuth"
        >
          &#124;
        </span>
        <button
          v-if="!isAuth"
          class="footer__wcag"
          data-testid="footer-support-button"
          @click="openSupport"
        >
          {{ $t('Support') }}
        </button>
      </div>
      <div
        class="footer__other"
        id="footer__other"
        :style="loginColors ? { 'background-color': loginColors['color-dark-primary'] } : {}">
        <div
          class="footer__copyright"
          data-testid="footer-copyright"
        >
          © {{ currentYear }} Changing Health
        </div>
        <span class="footer__separator">&#124;</span>
        <a
          class="footer__wcag"
          data-testid="footer-accessibility"
          href="https://www.changinghealth.com/policies/privacy-policy/"
          target="_blank"
        >
          {{ $t('Privacy Policy') }}
        </a>
        <span class="footer__separator">&#124;</span>
        <a
          class="footer__wcag"
          data-testid="footer-accessibility"
          href="https://www.changinghealth.com/policies/terms-and-conditions/"
          target="_blank"
        >
          {{ $t('Terms and conditions') }}
        </a>
        <template v-if="isProgramIdHL">
          <span class="footer__separator">&#124;</span>
          <a
            class="footer__wcag"
            data-testid="footer-accessibility"
            href="https://www.changinghealth.com/policies/privacy-policy/healthy-living-fair-processing-notice/"
            target="_blank"
          >
            {{ $t('Fair Processing Notice') }}
          </a>
          <span class="footer__separator">&#124;</span>
          <a
            class="footer__wcag"
            data-testid="footer-accessibility"
            href="https://www.changinghealth.com/policies/terms-and-conditions/service-description-healthy-living/"
            target="_blank"
          >
            {{ $t('Service Description') }}
          </a>
          <span class="footer__separator">&#124;</span>
          <a
            class="footer__wcag"
            data-testid="footer-accessibility"
            href="https://www.healthyliving.nhs.uk/policies/accessibility-statement"
            target="_blank"
          >
            {{ i18n('accessibility-statement') }}
          </a>
        </template>
        <template v-if="isProgramIdStride">
          <span class="footer__separator">&#124;</span>
          <a
            class="footer__wcag"
            data-testid="footer-accessibility"
            href="https://www.changinghealth.com/policies/terms-and-conditions/service-description-stride/"
            target="_blank"
          >
            {{ $t('Service Description') }}
          </a>
        </template>
        <template v-if="!isProgramIdHL">
          <span class="footer__separator">&#124;</span>
          <a
            class="footer__wcag"
            data-testid="footer-accessibility"
            href="https://www.changinghealth.com/accessibility-statement/"
            target="_blank"
          >
            {{ i18n('accessibility-statement') }}
          </a>
        </template>
      </div>
    </div>
  </footer>
</template>

<script>
  import { debounce } from 'lodash'
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import Feedback from '@/components/feedback/Feedback'

  import { PAGE_TYPES } from '@/constants'
  import i18n from '@/mixins/i18n'

  import eventBus from '@/tools/event-bus'

  export default {
    components: {
      Feedback,
    },

    mixins: [i18n],

    mounted() {
      this.debouncedCb = debounce(this.toggleFooter, 200)
      this.toggleListeners()
      const white = document.getElementById('footer__white').offsetHeight
      const other = document.getElementById('footer__other').offsetHeight
      document.getElementById('footer__inner').style.height = `${white + other}px`
    },

    beforeDestroy() {
      clearTimeout(this.footerTimeout)
      clearTimeout(this.listenersTimeout)
      this.toggleListeners()
    },

    watch: {
      isLoading: {
        handler(newValue, oldValue) {
          if (!this.hasFixedLoadingIndicator && newValue) return

          if (newValue) {
            return this.setIsFooterVisible(false)
          }

          if (!newValue && oldValue) {
            if (this.$static.hasListeners) {
              this.toggleListeners()
            }

            this.toggleListeners()
          }

          this.toggleFooter(150)
        },
        immediate: true,
      },
      $route() {
        this.setIsFooterVisible(false)
        this.toggleFooter(600)

        this.listenersTimeout = setTimeout(() => {
          if (this.$static.hasListeners) {
            this.toggleListeners()
          }

          this.toggleListeners()
        }, 600)
      },
    },

    methods: {
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapMutations('layout', [
        'set',
      ]),
      getContainersHeight() {
        const main = this.getMain()
        const containers = main.children || []
        const lastConainer = containers[containers.length - 1]
        const lastConainerStyles = window.getComputedStyle(lastConainer)
        const lastContainerPaddingBottom = parseFloat(lastConainerStyles.paddingBottom)

        return [...containers].reduce((acc, curr) => {
          const { height } = curr.getBoundingClientRect()

          return acc + height
        }, 0) - lastContainerPaddingBottom
      },
      getAppHeightDiff() {
        return document.body.scrollHeight - window.innerHeight
      },
      getMainHeightDiff() {
        const footerHeight = this.getFooterHeight()
        const mainHeight = this.getMain()?.clientHeight || 0
        const mainWithoutFooter = mainHeight - footerHeight
        const containersHeight = this.getContainersHeight()
        const containersMainDiff = containersHeight - mainWithoutFooter

        return containersMainDiff
      },
      getMain() {
        return document.querySelector('.main')
      },
      getFooterHeight() {
        return this.$refs.footer?.clientHeight || 0
      },
      setIsFooterVisible(value) {
        this.set({ value, state: 'isFooterVisible' })
      },
      toggleFooter(timeout = 0) {
        this.footerTimeout = setTimeout(() => {
          const {
            heightDiff,
            scrollTop,
          } = this.handleFooter()

          this.setIsFooterVisible(
            (scrollTop == 0 && heightDiff <= 0)
            || (scrollTop >= heightDiff),
          )
        }, timeout)
      },
      handleFooter() {
        const heightDiff = this.getMainHeightDiff()
        const { scrollTop = 0 } = this.getMain() || {}

        return { heightDiff, scrollTop }
      },
      toggleListeners() {
        const { hasListeners } = this.$static
        const action = hasListeners ? 'remove' : 'add'
        const busAction = hasListeners ? 'off' : 'on'

        const main = this.getMain()

        this.$static.hasListeners = !hasListeners

        /* eslint-disable no-unused-expressions */
        window[`${action}EventListener`]('resize', this.debouncedCb)
        main?.[`${action}EventListener`]('scroll', this.debouncedCb)
        main?.querySelector('.container')?.[`${action}EventListener`]('scroll', this.debouncedCb)
        eventBus?.[`$${busAction}`]('stepper-animation-finished', this.debouncedCb)
        eventBus?.[`$${busAction}`]('stepper-click', this.debouncedCb)
        eventBus?.[`$${busAction}`]('accordion-changed', this.debouncedCb)
        /* eslint-enable no-unused-expressions */
      },
      openSupport() {
        this.$router.push({ name: 'Support' })
      },
    },

    computed: {
      ...mapState('layout', [
        'hasFixedLoadingIndicator',
        'isStepperContentVisible',
        'isFooterVisible',
        'wW',
      ]),
      ...mapState('loading', [
        'loadings',
      ]),
      ...mapState('colors', [
        'loginColors',
      ]),
      ...mapGetters('user', [
        'getTimezoneOffset',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      isVueCtkDateTimePickerMobile() {
        return this.wW < 415
      },
      canFooterBeVisible() {
        return !this.isStepperContentVisible || !this.isVueCtkDateTimePickerMobile
      },
      isLoading() {
        return Object.values(this.loadings).some(Boolean)
      },
      isVisible() {
        return this.isFooterVisible || this.wW < 1024
      },
      currentYear() {
        return moment.utc().utcOffset(this.getTimezoneOffset).format('YYYY')
      },
      isProgramIdHL() {
        return this.currentProgramId === 83
      },
      isProgramIdStride() {
        return this.currentProgramId === 118
      },
      isAuth() {
        return this.$route.meta.type === PAGE_TYPES.auth
      },
    },

    static() {
      return {
        hasListeners: false,
      }
    },

    slug: 'component.base.base-footer',
  }
</script>

<style lang="scss">
  .footer {
    width: 100%;
    height: fit-content;
    transition: opacity 0.2s;
    z-index: z-index(footer);
    @include min-lg {
      position: absolute;
      bottom: 0;
      height: $footer-height--lg;
      opacity: 0;
      visibility: hidden;
    }
    &--is-visible {
      visibility: visible;
      opacity: 1;
    }
    &__inner {
      position: relative;
      width: 100%;
      height: fit-content;
      @include min-lg {
        height: $footer-height--lg;
      }
    }
    &__white {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: $footer-height/2;
      color: color(dark-primary);
      font-size: 0.75rem;
      font-weight: 600;
      background-color: color(_white);
      @include min-lg {
        height: $footer-height--lg/2;
        font-size: 1rem;
      }

      .footer__wcag {
        &:visited {
          color: color(dark-primary);
        }
      }
    }
    &__other {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: absolute;
      bottom: 0;
      z-index: z-index(footer);
      width: 100%;
      height: fit-content;
      color: color(_white);
      font-size: 0.75rem;
      background-color: color(dark-primary);
      font-weight: 600;
      padding: 1rem;
      @include min-lg {
        flex-direction: row;
        height: $footer-height--lg/2;
        font-size: 1rem;
      }

      .footer__copyright {
        order: 1;
        margin-top: 0.5rem;
        @include min-lg {
          order: 0;
          margin-top: 0;
        }
      }
      .footer__wcag {
        &:not(:last-child) {
          margin-bottom: 0.5rem;
        }
        @include min-lg {
          margin-bottom: 0;
        }
        &:visited {
          color: color(_white);
        }
      }
    }
    &__separator {
      display: none;
      padding: 0 1rem;
      @include min-lg {
        display: inline;
      }
    }

    &__wcag {
      width: fit-content;
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }
  }
</style>
