<template>
  <auth-wrapper
    class="update-policies"
    data-testid="auth-update-policies"
    :loading="$options.loading"
    @onSubmit="submit"
  >
    <template #title>
      {{ $t('Review the following updates') }}
    </template>

    <template>
      <checkbox-group
        class="nhsuk-u-margin-top-3 checkbox-group nhsuk-u-font-size-32"
        id="legal-document-accept"
        :label="'I have reviewed and accept:'"
        :name="'accept'"
        :testid="'dialog-legal-document'"
        :validation="$options.validation.agree"
        v-model="agree"
      />
      <ul
        class="nhsuk-list nhsuk-list--bullet nhsuk-u-margin-bottom-0"
        v-if="policiesToAccept">
        <li
          class="consent-item"
          v-for="(consent, i) in policiesToAccept.newConsentsNotAccepted"
          :key="i">
          <a
            :href="consent.url"
            class="anchor"
            target="_blank"
            >{{ consent.title || consent.identifier }}</a>
        </li>
      </ul>
    </template>

    <template #action>
      <div class="update-policies__actions">
        <base-button
          light
          data-testid="auth-login-cancel"
          @click="onCancel()"
        >
          {{ $t('Cancel') }}
        </base-button>
        <base-button
          class="login__login-button"
          data-testid="auth-login-submit"
          :type="'submit'"
          :disabled="!agree"
        >
          {{ $t('Save changes') }}
        </base-button>
      </div>
    </template>
  </auth-wrapper>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'

  import { BaseButton } from '@/components/base'
  import CheckboxGroup from '@/components/forms/checkbox-group/CheckboxGroup'
  import AuthWrapper from '@/components/wrappers/AuthWrapper'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-login.meta.title'),
      }
    },

    components: {
      AuthWrapper,
      BaseButton,
      CheckboxGroup,
    },

    data() {
      return {
        agree: false,
      }
    },

    methods: {
      ...mapActions('auth', [
        'updateConsentPolicies',
        'setPoliciesToAccept',
        'logout',
      ]),
      ...mapMutations('auth', [
        'setPoliciesToAccept',
      ]),
      submit() {
        this.updateConsentPolicies(this.user.id)
        .then(() => {
          this.setPoliciesToAccept(null)
          this.$router.replace({ name: 'Home' })
        })
      },
      onCancel() {
        this.logout()
        .then(() => { this.$router.replace({ name: 'AuthLogin' }); this.$router.push({ name: 'AuthLoggedOut' }) })
      },
    },

    computed: {
      ...mapState('auth', [
        'policiesToAccept',
      ]),
      ...mapState('user', [
        'user',
      ]),
    },

    slug: 'views.auth.auth-login',

    validation: {
      agree: {
        checkbox_required: true,
      },
    },
  }
</script>

<style lang="scss">
  .update-policies__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .checkbox-group {
    .base-checkbox__container {
      margin-bottom: 0 !important;
    }
    label {
      font-size: 18px !important;
      align-items: normal;
    }
    span.base-checkbox__check {
      margin-top: 0.2rem;
    }
  }
  li.consent-item a {
    font-weight: normal !important;
  }
</style>
