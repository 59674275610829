<template>
  <base-main
    data-testid="goals-available-profiles"
    :loading="$options.loading">
    <container
      first
      narrow>
      <stepper-custom-form
        ref="form"
        data-testid="goals-available-profiles-stepper"
        :buttonLabels="{ finishLabel: 'Next' }"
        :isNextDisabled="availableProfile === null"
        @cancel="goBack"
        @submit="onSubmit"
      >
        <stepper-custom-step
          data-testid="goals-available-profiles-step"
          title="What kind of goal do you want to set?"
        >
          <radio-multi-group
            :legend="$t('Choose from:')"
            :name="'available-profiles'"
            :options="availableGoalsProfilesSelect"
            v-model="availableProfile"
          />
        </stepper-custom-step>
      </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { isNil } from 'lodash'
  import { mapActions, mapGetters } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import { RadioMultiGroup } from '@/components/forms/radio'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import goBack from '@/mixins/goBack'

  export default {
    metaInfo() {
      return {
        title: 'Set a Goal',
      }
    },

    components: {
      BaseMain,
      StepperCustomForm,
      StepperCustomStep,
      RadioMultiGroup,
      Container,
    },

    mixins: [
      goBack,
    ],

    created() {
      const triggerDataId = this.getTriggerDataIdByPluginName('goal')
      const userId = this.getCurrentUserId

      if (isNil(triggerDataId)) {
        return
      }
      return this.fetchPlugin({ triggerDataId, userId })
    },

    data() {
      return {
        availableProfile: null,
      }
    },

    methods: {
      ...mapActions('goals', [
        'fetchPlugin',
      ]),
      onSubmit() {
        const { link } = this.availableGoalsProfiles
          .find(profile => profile.label === this.availableProfile)
        this.$router.push(link)
      },
    },

    computed: {
      ...mapGetters('goals', [
        'getUnlockedProfiles',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      ...mapGetters('triggers', [
        'getTriggerDataIdByPluginName',
      ]),
      availableGoalsProfiles() {
        return this.getUnlockedProfiles
          .filter(profile => profile?.answerable)
          .map(profile => ({
            label: profile.title,
            link: { name: 'GoalsAddGoal', params: { profileId: profile.id } },
          }))
      },
      availableGoalsProfilesSelect() {
        return this.availableGoalsProfiles.map(profile => ({
          label: profile.label,
          value: profile.label,
        }))
      },
    },

    loading: [
      'goals/fetchPlugin',
    ],
  }

</script>
