var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-group',_vm._b({attrs:{"events":['change'],"mode":'lazy'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"file-input",class:{ 'file-input--has-error': errors.length }},[_c('label',{staticClass:"file-input-label",attrs:{"for":_vm.name,"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.dispatchInputClick.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.labelText)+" "),_c('input',_vm._b({ref:"input",staticClass:"file-input-input",attrs:{"data-testid":"file-input-input","type":"file","id":_vm.name},on:{"change":function($event){return _vm.onChange($event, validate)}}},'input',{ accept: _vm.accept, name: _vm.name },false))])])]}}])},'form-group',{
    name: _vm.name,
    validation: _vm.validation,
    value: _vm.value,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }