<template>
  <base-main
    appbar
    data-testid="auth-confirm"
    :loading="$options.loading">
      <container
        v-if="isFailed"
        first
        white
      >
        <h2
          data-testid="auth-confirm-heading"
        >
          {{ $t(`${$options.slug}.failed`) }}
        </h2>
        <i18n
          data-testid="auth-confirm-link"
          :path="`${$options.slug}.broken-link`"
          tag="p"
        >
          <template #linebreak>
            <br>
          </template>
          <template #email>
            <a
              data-testid="auth-confirm-email"
              href="mailto:customersupport@changinghealth.com"
            >
              customersupport@changinghealth.com
            </a>
          </template>
        </i18n>

        <base-button
          align-end
          data-testid="auth-confirm-back"
          :link="{ name: 'Landing' }"
        >
          {{ $t('Go back to Login Page') }}
        </base-button>
    </container>
  </base-main>
</template>

<script>
  import { mapActions } from 'vuex'

  import {
    BaseButton,
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-confirm.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
    },

    props: {
      token: {
        type: String,
        required: false,
        default: '',
      },
      emailHash: {
        type: String,
        required: false,
        default: '',
      },
    },

    mounted() {
      this.confirmRegistration({
        token: this.token,
        email: this.emailHash,
      })
      .then(() => this.$router.replace({ name: 'Home' }))
      .catch(() => this.isFailed = true)
    },

    data() {
      return {
        isFailed: false,
      }
    },

    methods: {
      ...mapActions('user', [
        'confirmRegistration',
      ]),
    },

    slug: 'views.auth.auth-confirm',

    loading: [
      'user/confirmRegistration',
    ],
  }
</script>

<style>
  .activation-failed {
    padding-top: 2rem;
  }
</style>
