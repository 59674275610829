<template>
  <base-main
    class="coaching-content"
    :data-testid="'coaching-plugin-show-content'"
    :loading="$options.loading"
  >
    <transition-article>
      <coaching-content-item
        v-if="item"
        :key="id"
        :articleLink="'CoachingPluginContentArticle'"
        :createdAt="item.created_at"
        :comment="item.comment"
        :description="item.item.description"
        :subjectId="item.item.subject_id"
        :title="item.item.title"
        :type="item.item.type"
        :url="item.item.url"
        :testid="'coaching-plugin-show-content'"
      />
    </transition-article>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import { CoachingContentItem } from '@/components/coaching'

  import TransitionArticle from '@/transitions/transition-article/TransitionArticle'

  export default {
    metaInfo() {
      const { title } = this.item?.item || {}

      return {
        title: [
          this.$t('views.plugins.coaching-plugin.coaching-plugin-show-content.meta.title'),
          title,
        ].filter(Boolean).join(' - '),
      }
    },

    components: {
      BaseMain,
      CoachingContentItem,
      TransitionArticle,
    },

    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },

    watch: {
      id: {
        handler() {
          this.fetchCoachAndContentItem()
        },
        immediate: true,
      },
    },

    data() {
      return {
        isPluginUnavailable: false,
      }
    },

    methods: {
      ...mapActions('coaching', [
        'fetchCoach',
        'fetchContentItem',
      ]),
      fetchCoachAndContentItem() {
        const data = {
          itemId: this.id,
          userId: this.getCurrentUserId,
          programId: this.currentProgramId,
        }

        if (!this.item) this.fetchContentItem(data)
        if (!this.coach) {
          this.fetchCoach({ programId: this.currentProgramId, userId: this.getCurrentUserId })
            .catch(() => this.$router.replace({ name: 'CoachingPlugin' }))
        }
      },
      getViewButtonProps(item) {
        if (item.type == 1) { // article
          return { link: { name: 'CoachingPluginContentArticle', params: { id: item.subject_id } } }
        } else if (item.type == 2) { // website
          return { link: item.url }
        } else if (item.type == 3) { // plugin
          try {
            return { link: { name: this.getTriggerRouteByPluginId(item.subject_id) } }
          } catch (err) {
            this.isPluginUnavailable = true
          }
        }
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
        'getContentItemById',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      ...mapGetters('triggers', [
        'getTriggerRouteByPluginId',
        'hasTriggers',
      ]),
      ...mapState('coaching', [
        'contentItem',
        'coach',
      ]),
      item() {
        const contentItem = this.contentItem?.id == this.id
          ? this.contentItem
          : null

        return contentItem || this.getContentItemById(this.id)
      },
    },

    loading: [
      'coaching/fetchContentItem',
      'coaching/fetchCoach',
    ],
  }
</script>

<style lang="scss">
  .coaching-content {
    &-header {
      display: flex;
    }
    &-image {
      flex-shrink: 0;
      width: 5.4rem;
      margin-right: 1.8rem;
      opacity: 0.5;
      fill: color(primary);
    }
    &-date {
      margin: 0 0 0.5rem 0;
    }
    &-text {
      margin: 3rem 0;
      font-size: 1.6rem;
      line-height: 1.4;
    }
    &-actions-container {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: center;
    }
    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-actions-svg {
      &:first-of-type {
        margin: 0 1.6rem 0 1rem;
      }
      svg {
        width: auto;
        height: 2rem;
        fill: color(primary);
      }
    }
  }
</style>
