<template>
    <tracking-delete-stepper
        @cancel="cancel"
        @onDelete="onDelete" />
</template>
<script>
  import { mapActions } from 'vuex'

  import TrackingDeleteStepper from '@/components/tracking/TrackingDeleteStepper'

  export default {
    components: {
      TrackingDeleteStepper,
    },

    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
    },

    methods: {
      ...mapActions('weightTracking', [
        'deleteWeight',
      ]),
      cancel() {
        this.$router.push({ name: 'WeightTracking' })
      },
      async onDelete() {
        try {
          await this.deleteWeight(this.id)
          this.$router.push({ name: 'WeightTracking' })
        } catch (err) {
          //
        }
      },
    },
  }

</script>
