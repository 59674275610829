<template>
  <form-group
    :events="['change']"
    :mode="'lazy'"
    v-bind="{
      name,
      validation,
      value,
    }"
    v-slot="{ errors, validate }">
      <div
        class="file-input"
        :class="{ 'file-input--has-error': errors.length }">
          <label
            class="file-input-label"
            :for="name"
            tabindex="0"
            @keydown.prevent.enter.space="dispatchInputClick">
              {{ labelText }}
              <input
                class="file-input-input"
                data-testid="file-input-input"
                type="file"
                ref="input"
                :id="name"
                v-bind="{ accept, name }"
                @change="onChange($event, validate)">
          </label>
      </div>
  </form-group>
</template>

<script>
  import FormGroup from '@/components/forms/form-group/FormGroup'

  import labelText from '@/mixins/labelText'

  export default {
    components: {
      FormGroup,
    },

    mixins: [
      labelText,
    ],

    props: {
      accept: {
        type: String,
        required: false,
        default: null,
      },
      label: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
      validation: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      value: {
        type: String,
        required: false,
        default: null,
      },
    },

    methods: {
      dispatchInputClick() {
        this.$refs.input.click()
      },
      async onChange(e, validate) {
        const { valid } = await validate(e.target.files)

        if (valid) {
          this.$emit('change', e)
        }
      },
    },
  }
</script>

<style lang="scss">
  .file-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem 0 0 0;
    &--has-error {
      .file-input-label {
        color: color(error);
        &:before {
          background-color: color(error);
        }
      }
    }
    &-input {
      position: absolute;
      left: 0;
      width: 0;
      height: 0;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      overflow: hidden;
      z-index: z-index(under-base);
    }
    &-label {
      position: relative;
      color: color(primary);
      font-size: 1.6rem;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: color(primary);
        opacity: 0.7;
      }
    }
  }
</style>
