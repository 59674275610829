<template>
  <base-main
    no-top
    data-testid="auth-sign-up"
    :loading="$options.loading"
  >
    <div class="sign-up">
      <simple-tile
        class="sign-up__wrapper"
        :direction="'column'"
      >
        <h1
          class="sign-up__title"
          data-testid="auth-sign-up-header"
        >
          {{ $t($options.statics.header) }}
        </h1>
        <p
          data-testid="auth-sign-up-description"
        >
          {{ $t($options.statics.description) }}
        </p>

        <profile-form
          class="sign-up__form"
          data-testid="auth-sign-up-profile-form"
          :hasSubmitButton="false"
          :isProfile="false"
          :method="'PATCH'"
          :user="userInfo"
          v-bind="{ isSaving }"
          @submit="onSubmit"
        >
          <input-details
            v-if="hasErrorOccured"
            class="sign-up__custom-error"
            data-testid="auth-sign-up-error"
            :inputError="errorMessage"
          />

          <base-button
            class="sign-up__submit"
            data-testid="auth-sign-up-submit"
            :isLoading="isSaving"
            :type="'submit'"
          >
            {{ $t('Continue') }}
          </base-button>
        </profile-form>
      </simple-tile>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import {
    BaseButton,
    BaseMain,
  } from '@/components/base'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import ProfileForm from '@/components/forms/profile-form/ProfileForm'
  import SimpleTile from '@/components/tiles/simple-tile'
  import { PhoneTakenError, UnknownError } from '@/extensions/error'
  import localStorage from '@/tools/local-storage'

  const INVITATION_INFO_KEY = 'AuthInvitation_invitationInfo'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-sign-up.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      InputDetails,
      ProfileForm,
      SimpleTile,
    },

    props: {
      authForm: {
        type: Object,
        default: null,
      },
      isUserFetched: {
        type: Boolean,
        default: true,
      },
      token: {
        type: String,
        required: false,
        default: '',
      },
      email: {
        type: String,
        default: '',
      },
    },

    created() {
      if (this.authForm && this.userData) {
        localStorage.set(INVITATION_INFO_KEY, {
          form: this.authForm,
          user: this.userData,
        })
      }

      const invitationInfo = localStorage.get(INVITATION_INFO_KEY)
      if (invitationInfo) {
        this.storageUserData = invitationInfo.user
        this.storageAuthForm = invitationInfo.form
      }
    },

    mounted() {
      if (this.shouldRedirectToLogin) {
        this.$router.replace({ name: 'AuthLogin' })
      }
    },

    data() {
      return {
        errorMessage: '',
        hasErrorOccured: false,
        storageUserData: null,
        storageAuthForm: null,
      }
    },

    methods: {
      ...mapActions('auth', [
        'confirmInvitation',
        'isInvitationNewUser',
        'loginWithCredentials',
      ]),
      ...mapActions('dialog', [
        'openDialog',
      ]),
      ...mapActions('user', [
        'register',
        'updateUser',
        'setEmail',
      ]),
      onSubmit({ form }) {
        if (!this.userInfo.email) {
          return this.setUsersEmail()
        }

        this.userInfo
          ? this.completeUserRegistration(form)
          : this.registerFromPublicLink(form)
      },
      setUsersEmail() {
        const data = {
          email: this.form.email,
          token: this.token,
          _method: 'PATCH',
        }

        this.setEmail(data)
          .then(this.completeUserRegistration)
          .catch(error => {
            const errorData = error?.response?.data

            if (errorData?.errors?.email[0]) {
              this.errorMessage = errorData?.errors?.email[0]
            } else {
              this.errorMessage = errorData?.message
            }

            this.hasErrorOccured = true
          })
      },
      completeUserRegistration(form) {
        const userInfo = this.invitationUser || this.storageUserData
        if (this.userForm) {
          Object.assign(this.userForm, { login: userInfo.email })
        }

        const { id: userId } = this.userForm
          ? userInfo
          : this.user

        const loginIfCredentials = this.userForm
          ? this.loginWithCredentials(this.userForm)
          : Promise.resolve()

        loginIfCredentials
          .then(() => this.updateUser({ form, userId }))
          .then(() => this.$router.push({ name: 'Home' }))
          .catch(this.handleError)
          .finally(() => {
            localStorage.unset(INVITATION_INFO_KEY)
          })
      },
      registerFromPublicLink(form) {
        const regData = { token: this.token, ...this.userForm }

        Object.entries(form)
          .filter(([, value]) => value !== null)
          .forEach(([key, val]) => regData[key] = regData[key] || key === 'timezone'
            ? val.value
            : val)

        this.register(regData)
          .then(() => this.openDialog({ component: 'UserEmailConfirm', props: { email: this.email } }))
          .then(() => this.$router.push({ name: 'Home' }))
          .catch(this.handleError)
      },
      handleError(error) {
        const { status_code, errors } = error

        /* eslint-disable-next-line camelcase */
        if (status_code === 422) {
          this.errorMessage = this.hasPhoneErrors(errors)
            ? new PhoneTakenError().message
            : new UnknownError().message
        } else {
          this.errorMessage = error.message
        }
        this.hasErrorOccured = true
      },
      hasPhoneErrors({ phone, landline_phone }) {
        /* eslint-disable-next-line camelcase */
        return phone?.length > 0 || landline_phone?.length > 0
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('auth', [
        'invitationUser',
      ]),
      ...mapState('user', [
        'user',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'auth/loginWithCredentials',
          'user/register',
          'user/updateUser',
        ])
      },
      shouldRedirectToLogin() {
        return !this.userForm && !this.userInfo
      },
      userData() {
        return this.invitationUser || this.user
      },
      userInfo() {
        return this.userData || this.storageUserData
      },
      userForm() {
        return this.authForm || this.storageAuthForm
      },
    },

    loading: [
      'auth/isInvitationNewUser',
    ],

    statics: {
      header: 'views.auth.auth-sign-up.header',
      description: 'views.auth.auth-sign-up.description',
      measurements: 'views.auth.auth-sign-up.measurements',
    },
  }
</script>

<style lang="scss">
  .sign-up {
    @include view-wrapper(77.5rem);
    .simple-tile__content {
      margin-bottom: 0;
      @include min-lg {
        padding: 1.2rem;
      }
    }
    &__title {
      margin-top: 0;
      font-size: 2.1rem;
    }
    &__form {
      margin: 2rem 0 0;
    }
    &__measurements-label {
      margin: 4.5rem 0 1.5rem;
      font-weight: 600;
    }
    &__custom-error {
      margin: 3.5rem 0 0;
    }
    &__submit {
      align-self: flex-start;
      margin: 2rem 0 0;
      @include min-lg {
        margin: 3.5rem 0 0;
      }
    }
    .phone-input-group-label {
      max-width: 78.1vw;
      white-space: nowrap;
      @include min-sm {
        max-width: none;
        white-space: pre-wrap;
      }
      .sign-up__label-attribution {
        margin-left: 0.4rem;
        font-weight: 400;
        text-overflow: ellipsis;
        overflow: hidden;
        @include min-sm {
          margin-left: 0;
        }
      }
    }
  }
</style>
