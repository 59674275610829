var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepper-step",class:[
    { 'stepper-step--is-current': _vm.isCurrent },
    { 'stepper-step--is-disabled': _vm.isDisabled },
    { 'stepper-step--is-last': _vm.isLast },
    { 'stepper-step--is-next': _vm.isNext },
    { 'stepper-step--is-previous': _vm.isPrevious },
    { 'stepper-step--has-errors' : _vm.hasErrors },
    { 'stepper-step--show-overflow' : _vm.showOverflow } ],attrs:{"data-testid":"stepper-old-step"}},[_c('stepper-icons',_vm._b({attrs:{"step":_vm.step + 1}},'stepper-icons',{ hasErrors: _vm.hasErrors, isCurrent: _vm.isCurrent, isEdit: _vm.isEdit, isNext: _vm.isNext, isPrevious: _vm.isPrevious },false)),_c('div',{staticClass:"stepper-container"},[_c('h2',{staticClass:"stepper-label",attrs:{"aria-controls":("stepper-content-" + _vm.uuid),"aria-expanded":_vm.isCurrent.toString(),"data-testid":"stepper-old-step-label","tabindex":"0"},on:{"click":_vm.onClick,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.onClick.apply(null, arguments)}}},[_vm._t("label"),_c('transition-fade',[(_vm.isEdit && _vm.isPrevious)?_c('inline-svg',{staticClass:"stepper-edit",attrs:{"src":"pencil.svg","aria-label":"Edit"}}):_vm._e()],1)],2),_c('div',{staticClass:"stepper-content",attrs:{"data-testid":"stepper-old-content","id":("stepper-content-" + _vm.uuid),"aria-hidden":(!_vm.isCurrent).toString()}},[_c('div',[_vm._t("content")],2),_c('stepper-actions',_vm._b({attrs:{"isDisabled":_vm.isDisabled || _vm.isNextDisabled},on:{"nextStep":_vm.onNextStep,"previousStep":_vm.onPreviousStep}},'stepper-actions',{ hideDefaultButtons: _vm.hideDefaultButtons, isEdit: _vm.isEdit, isSaving: _vm.isSaving, maxStep: _vm.maxStep, step: _vm.step },false))],1)]),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stepper-line-container"},[_c('div',{staticClass:"stepper-line"})])}]

export { render, staticRenderFns }