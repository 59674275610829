<template>
  <div
    class="stepper"
    data-testid="stepper-old"
  >
    <slot
      v-bind="{ validationErrors }"/>
    <stepper-progress-indicator
      v-if="hasProgressIndicator"
      v-bind="{ isIos, progress }"/>
  </div>
</template>

<script>
  import scrollIntoView from 'smooth-scroll-into-view-if-needed'
  import { mapState } from 'vuex'

  import StepperProgressIndicator from '@/components/stepper/stepper-progress-indicator/StepperProgressIndicator'

  import eventBus from '@/tools/event-bus'

  export default {
    components: {
      StepperProgressIndicator,
    },

    props: {
      hasProgressIndicator: {
        type: Boolean,
        required: false,
        default: true,
      },
      validationErrors: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      slots: {
        type: Object,
        required: false,
        default: null,
      },
    },

    mounted() {
      this.toggleListeners('on')
    },

    beforeDestroy() {
      this.toggleListeners('off')
      clearTimeout(this.timeout)
    },

    watch: {
      currentStep() {
        this.$nextTick()
          .then(this.scrollToCurrentStep)
      },
      async maxStep() {
        this.toggleListeners('off')
        await this.$nextTick()
        this.toggleListeners('on')
      },
    },

    data() {
      return {
        currentStep: 0,
        timeout: null,
      }
    },

    methods: {
      toggleListeners(action) {
        const listeners = ['nextStep', 'previousStep', 'setStep']

        listeners.forEach(listener => {
          eventBus[`$${action}`](listener, this[listener])
          this.$children.forEach(children => children[`$${action}`](listener, this[listener]))
        })
      },
      nextStep() {
        this.currentStep += 1
      },
      previousStep() {
        this.currentStep -= 1
      },
      setStep(step) {
        this.currentStep = step
      },
      scrollToCurrentStep() {
        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => {
          const currentStep = document.querySelector('.stepper-step--is-current')

          scrollIntoView(currentStep, {
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          })
        }, 650)
      },
    },

    computed: {
      ...mapState('layout', [
        'os',
      ]),
      isIos() {
        return this.os == 'ios'
      },
      maxStep() {
        const slots = this.slots || this.$slots

        return slots.default
                .filter(vnode => Boolean(vnode.tag))
                .length - 1
      },
      progress() {
        return ((this.currentStep + 1) / (this.maxStep + 1)) * 100
      },
    },
  }
</script>

<style lang="scss">
  .stepper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
</style>
