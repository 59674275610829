<script>
  import ProgressIndicator from '@/components/progress-indicator/ProgressIndicator'

  export default {
    functional: true,

    props: {
      isIos: {
        type: Boolean,
        required: false,
        default: false,
      },
      progress: {
        type: Number,
        required: true,
      },
    },

    render(h, { props }) {
      const { prop, tag } = props.isIos
                              ? { tag: 'portal', prop: { to: 'progress' } }
                              : { tag: 'div' }
      return h(tag, {
        props: prop,
      }, [
        h('div', {
          staticClass: 'stepper-progress-indicator',
          class: {
            'stepper-progress-indicator--portal': props.isIos,
          },
          attrs: {
            'data-testid': 'stepper-old-progress-indicator',
          },
          props: prop,
        }, [
          h(ProgressIndicator, {
            staticClass: 'stepper-progress-indicator-container',
            props: {
              progress: props.progress,
            },
          }),
        ]),
      ])
    },
  }
</script>

<style lang="scss">
  .stepper-progress-indicator {
    position: fixed;
    left: 0;
    top: $header_height + 1.1rem;
    z-index: z-index(content);
    width: 100%;
    @include min-lg {
      top: $header_height--lg + 1.1rem;
    }
    &--portal {
      .stepper-progress-indicator-container.progress-indicator {
        margin: 0 auto;
      }
    }
    &-container.progress-indicator {
      margin: auto;
      @media all and (min-width: map-get($breakpoints, lg)) {
        width: $container_width--lg;
      }
      @media all and (min-width: map-get($breakpoints, xl)) {
        width: $container_width--xl;
      }
    }
  }
  .container--narrow {
    .stepper-progress-indicator-container {
      max-width: 600px;
      @media all and (min-width: map-get($breakpoints, sm)) {
        width: 600px;
      }
    }
  }
  .edge {
    .container--narrow {
      .stepper-progress-indicator-container {
        transform: translateX(-0.8rem);
      }
    }
  }
</style>
