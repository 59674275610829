<template>
  <base-main
    data-testid="goals-edit-goal"
    :loading="$options.loading">
      <goals-form
        is-edit-mode
        data-testid="goals-edit-goal-form"
        v-bind="{ isSaving, profileId }"
        @submit="onSubmit"
      />
  </base-main>
</template>

<script>
  import { isNil } from 'lodash'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import { BaseMain } from '@/components/base'
  import GoalsForm from '@/components/goals/GoalsForm'

  import goBack from '@/mixins/goBack'
  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  export default {
    metaInfo() {
      const { goalTitle } = this

      return {
        title: [
          this.$t('views.goals.edit-goal.meta.title'),
          goalTitle,
        ].filter(Boolean).join(' - '),
      }
    },

    components: {
      BaseMain,
      GoalsForm,
    },

    mixins: [
      goBack,
      weightUnitConverter,
    ],

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    created() {
      this.fetchStuff()
    },

    destroyed() {
      this.resetBreadcrumbs()
    },

    data() {
      return {
        form: null,
      }
    },

    methods: {
      ...mapActions('goals', [
        'editGoal',
        'fetchGoal',
        'fetchPlugin',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      ...mapMutations('route', [
        'setBreadcrumbCategory',
        'resetBreadcrumbs',
      ]),
      fetchStuff() {
        const promises = []

        if (!this.plugin) {
            promises.push(this.fetchPlugin({
              triggerDataId: this.getTriggerDataIdByPluginName('goal'),
              userId: this.getCurrentUserId,
            }))
        }
        if (this.goal?.id !== this.id) promises.push(this.fetchGoal(this.id))

        Promise.all(promises)
          .then(() => {
            this.setBreadcrumbCategory(this.goalTitle)
          })
          .catch(this.goBack)
      },
      onSubmit(isValid, stepperActions) {
        if (!isValid || this.isSaving) return

        const answers = stepperActions.map(({ token, answers: actionAnswers }) => ({
          token,
          content: Object.values(actionAnswers).filter(answer => !isNil(answer) && answer !== ''),
        }))

        if (this.isWeightGoal && !this.isMetric) {
          if (this.isStonesAndPounds) {
            answers[0].content[0] = this.convertToKgsIfNeeded({
              stones: parseInt(answers[0].content[0], 10),
              pounds: parseInt(answers[0].content[1], 10),
            }).value
          } else {
            answers[0].content[0] = this.convertToKgsIfNeeded(
              parseInt(answers[0].content[0], 10),
            ).value
          }
          answers[0].content = [answers[0].content[0]]
        }

        this.editGoal({
          id: this.id,
          goal: {
            profile_id: this.goal.profile_id,
            program_id: this.currentProgramId,
            answers,
          },
        }).then(() => {
          this.addSnackbar({ message: this.$t('Goal edited') })
          this.$router.push({ name: 'GoalsPreviewGoal', params: { id: this.id } })
        }).catch(err => {
          this.addSnackbar({ message: 'Something went wrong. Please try again later.' })
          throw err
        })
      },
    },

    computed: {
      ...mapGetters('goals', [
        'getProfileById',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('triggers', [
        'getTriggerDataIdByPluginName',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'isMetric',
        'isStonesAndPounds',
      ]),
      ...mapState('goals', [
        'goal',
        'plugin',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'goals/editGoal',
        ])
      },
      profileId() {
        /* eslint-disable-next-line camelcase */
        return this.goal?.profile_id || -1
      },
      isWeightGoal() {
        /* eslint-disable-next-line camelcase */
        return this.goalProfile?.template_slug === 'weight_goal'
      },
      goalProfile() {
        return this.plugin && this.getProfileById(this.profileId)
      },
      goalTitle() {
        return this.goalProfile?.title
      },
    },

    loading: [
      'goals/fetchGoal',
      'goals/fetchPlugin',
    ],
  }
</script>
