<template>
  <base-main
    appbar
    white>
      <container
        first
        white
        class="landing">
          <p
            class="offline-info"
            v-html="$t(`${$options.slug}.info`)"
          >
            <router-link to="/">
              {{ $t(`${$options.slug}.again`) }}
            </router-link>.
          </p>
      </container>
  </base-main>
</template>

<script>
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.offline.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
    },

    slug: 'views.offline',
  }
</script>

<style lang="scss">
  .offline-info {
    text-align: center;
    font-size: 1.1em;
    line-height: 1.4;
    .router-link-active {
      text-decoration: underline;
    }
  }
</style>
