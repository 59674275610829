<template>
  <auth-wrapper
    has-language-select
    class="auth-invitation"
    data-testid="auth-invitation"
    :loading="$options.loading"
    @onSubmit="setUserPassword"
  >
    <template #error>
      <input-details
        v-if="errorMessage"
        class="auth-invitation__custom-error"
        data-testid="auth-invitation-error"
        :inputError="errorMessage"
      />
    </template>

    <template #title>
      {{ $t(`${$options.slug}.title`) }}
    </template>

    <template v-slot="{ touched }">
      <input-group
        v-if="requireEmail"
        has-hidden-asterisk
        :class="{ 'auth-invitation__error-border': showErrorBorder(touched) }"
        :label="`${$options.slug}.new-email`"
        :name="'email'"
        :type="'email'"
        :validation="$options.validation.email"
        v-model="form.email"
      />

      <validate-password
        :label="$t(`${$options.slug}.new-password`)"
        :name="'password'"
        :outsideDescription="true"
        :insideDescription="false"
        @validPassword="(password) => form.password = password"
      />
    </template>

    <template #action>
      <!-- <language-select
        ref="languageSelect"
        class="auth-invitation__select"
        data-testid="auth-invitation-language-select"
        :languages="packageTranslatedLanguages"
        @change="onLangChange"
        @ready="onLangsReady"
      /> -->
      <base-button
        class="auth-invitation__create-password"
        data-testid="auth-invitation-submit"
        :isDisabled="hasInvitationError"
        :isLoading="isSaving"
        type="submit"
      >
        {{ $t(`${$options.slug}.create-password`) }}
      </base-button>
    </template>
    <template #links>
      <router-link
        data-testid="auth-invitation-back"
        :to="{ name: 'AuthLogin' }"
      >
        {{ $t('Back to login') }}
      </router-link>
    </template>
  </auth-wrapper>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  // import { LanguageSelect } from '@/components/language'
  import ValidatePassword from '@/components/forms/validate-password/ValidatePassword'
  import AuthWrapper from '@/components/wrappers/AuthWrapper'

  import { getAllFocusables } from '@/helpers'

  import localStorage from '@/tools/local-storage'

  const WAS_CHANGED_KEY = 'AuthInvitation_wasLanguageChanged'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-invitation.meta.title'),
      }
    },

    components: {
      AuthWrapper,
      BaseButton,
      InputDetails,
      InputGroup,
      ValidatePassword,
      // LanguageSelect,
    },

    props: {
      invitationToken: {
        type: String,
        required: true,
      },
    },

    created() {
      this.fetchLanguagesCoveredByTranslations()

      this.isInvitationNewUser(this.invitationToken)
        .then(({ isNewUser, isPublicLink }) => {
          this.requireEmail = !!isPublicLink

          if (!(isNewUser || isPublicLink)) {
            this.hasInvitationError = true
            this.errorMessage = 'Link was used already used.'
          }
        })
        .catch(err => {
          this.hasInvitationError = true
          this.errorMessage = err.message
          // eslint-disable-next-line no-unused-expressions
          this.$el.querySelector('input')?.focus()
        })
    },

    destroyed() {
      if (!this.willReload) {
        localStorage.unset(WAS_CHANGED_KEY)
      }
    },

    data() {
      return {
        errorMessage: '',
        form: {
          password: '',
        },
        hasInvitationError: false,
        requireEmail: false,
        willReload: false,
      }
    },

    methods: {
      ...mapActions('auth', [
        'isInvitationNewUser',
        'confirmInvitation',
      ]),
      ...mapActions('languages', [
        'fetchLanguagesCoveredByTranslations',
      ]),
      ...mapActions('user', [
        'canRegisterWithCredentials',
      ]),
      setUserPassword(isValid) {
        if (!isValid) return

        this.isInvitationPrivate
          ? this.confirmPrivateInvitation()
          : this.confirmPackageInvitation()
      },
      confirmPrivateInvitation() {
        const credentials = {
          password_confirmed: this.form.password,
          login: this.invitationUser.email,
          ...this.form,
        }

        this.handleSignUp('confirmInvitation', { token: this.invitationToken, credentials })
      },
      confirmPackageInvitation() {
        this.handleSignUp('canRegisterWithCredentials', this.form)
      },
      handleError(error) {
        this.errorMessage = error.message
        // eslint-disable-next-line no-unused-expressions
        this.$el.querySelector('input')?.focus()
      },
      handleSignUp(action, params) {
        const {
          form: authForm,
          invitationToken: token,
        } = this
        const { email } = authForm
        const isUserFetched = false

        this[action](params)
          .then(() => this.$router.push({ name: 'AuthSignUp', params: { authForm, token, email, isUserFetched } }))
          .catch(this.handleError)
      },
      showErrorBorder(touched) {
        return this.errorMessage && !touched
      },
      onLangChange(newVal, oldVal) {
        if (newVal && oldVal) {
          localStorage.set(WAS_CHANGED_KEY, true)
          this.willReload = true
        }
      },
      onLangsReady() {
        if (localStorage.get(WAS_CHANGED_KEY, false)) {
          this.focusLanguageSelect()
        }
      },
      focusLanguageSelect() {
        const [focusable] = getAllFocusables(this.$refs.languageSelect?.$el)

        // eslint-disable-next-line no-unused-expressions
        focusable?.focus()
      },
    },

    computed: {
      ...mapGetters('languages', [
        'packageTranslatedLanguages',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('auth', [
        'invitationUser',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'user/canRegisterWithCredentials',
          'auth/confirmInvitation',
        ])
      },
      isInvitationPrivate() {
        return this.invitationToken && this.invitationUser
      },
    },

    slug: 'views.auth.auth-invitation',

    loading: [
      'auth/isInvitationNewUser',
    ],

    validation: {
      email: {
        required: true,
        email: true,
      },
      password: {
        required: true,
        min: 9,
        max: 32,
      },
    },
  }
</script>

<style lang="scss">
  .auth-invitation {
    &__custom-error {
      .input-details__error {
        text-overflow: ellipsis;
        overflow: hidden;
        & > a {
          text-decoration: underline;
        }
      }
    }
    &__tips {
      margin-top: 1rem;

      ul {
        font-size: inherit;
      }
    }
    &__buttons {
      display: flex;
      flex-direction: column;
    }
    &__create-password {
      min-width: 10rem;
      height: 3.2rem;
      margin: 1.8rem auto 1.8rem 0;
      font-size: 1.2rem;
      font-weight: 800;
      @include min-lg {
        min-width: 12rem;
        height: 4rem;
        padding: .2rem 3.5rem;
        font-size: 1.4rem;
      }
    }
    &__select {
      width: 100%;
    }
    &__error-border {
      .input-group__input {
        border-color: color(error);
      }
    }
    .auth__actions {
      flex-direction: column;
      align-items: flex-start;
    }
    .auth__links {
      margin-bottom: 1.8rem;
    }
  }
</style>
