<template>
  <div
    aria-live="polite"
    class="tracking-details-tile"
    :data-testid="dataTestid"
  >
    <simple-tile>
      <aside
        v-if="hasAnyValues"
        class="tracking-details-tile__layout"
        :aria-label="ariaLabel()"
      >
        <div
          v-for="(column, i) in columns"
          :key="column"
          class="tracking-details-tile__column"
          :data-testid="`${dataTestid}-column`"
        >
          <h3
            class="tracking-details-tile__label"
            :data-testid="`${dataTestid}-label`"
          >
            {{ $t(column) }}
          </h3>
          <p
            class="tracking-details-tile__value"
            :data-testid="`${dataTestid}-value`"
          >
            {{ values[i] }}
          </p>
        </div>
        <div
          v-if="hasAction"
          class="tracking-details-tile__action"
        >
          <slot name="action"/>
        </div>
      </aside>
      <div
        v-else
        class="tracking-details-tile__empty"
      >
        <p class="tracking-details-tile__value">
          Touch a point on the chart to view details.
        </p>
      </div>
    </simple-tile>
  </div>
</template>

<script>
  import SimpleTile from '@/components/tiles/simple-tile'

  import { DATE_FORMATS } from '@/constants'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      SimpleTile,
    },

    mixins: [
      testid('tracking-details-tile'),
    ],

    props: {
      columns: {
        type: Array,
        required: false,
        default: null,
      },
      values: {
        type: Array,
        required: false,
        default: null,
      },
    },

    methods: {
      ariaLabel() {
        let text = ''
        this.columns.forEach((column, i) => {
          text += `${column} ${this.values[i]} `
        })
        return text
      },
    },

    computed: {
      hasAction() {
        return !!this.$slots.action
      },
      hasAnyValues() {
        return !!this.values?.length
      },
      dateFormatString() {
        return this.period === 'year'
          ? DATE_FORMATS.monthYear
          : DATE_FORMATS.date
      },
    },
  }
</script>

<style lang="scss">
  $block: 'tracking-details-tile';

  .#{$block} {
    &__layout {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      @include min-md {
        flex-direction: row;
      }
    }
    &__column {
      &:not(:last-child) {
        margin: 0 0 2rem;
        @include min-md {
          margin: 0;
        }
      }
    }
    &__label {
      margin: 0 0 0.2rem;
      color: color(dark-primary);
      font-size: 1.2rem;
      font-weight: 600;
    }
    &__value {
      color: color(dark-grey);
      font-size: 1.8rem;
      font-weight: 600;
    }
    &__action {
      display: inline-flex;
      align-items: center;
    }
    .simple-tile {
      border: 1px solid color(primary);
      border-radius: 0px;
      box-shadow: none;
      &__main {
        min-height: 9.7rem;
        padding: 1.6rem;
        @include min-md {
          padding: 2.5rem;
        }
      }
      &__content {
        align-items: center;
      }
    }
  }
</style>
