<template>
  <div
    class="stepper-actions"
    data-testid="stepper-old-actions"
  >
    <base-button
      v-if="!isFirst && !hideDefaultButtons"
      data-testid="stepper-old-actions-prev"
      @click="goToPreviousStep"
    >
      {{ $t('Back') }}
    </base-button>

    <slot/>
    <base-button
      v-if="showButton"
      data-testid="stepper-old-actions-next"
      :isDisabled="(isSaving && isLast) || isDisabled"
      :isLoading="isSaving && isLast"
      :type="buttonProperties.type"
      @click="goToNextStep"
    >
      {{ $t(buttonProperties.text) }}
    </base-button>
  </div>
</template>

<script>
  import BaseButton from '@/components/base/BaseButton'

  export default {
    components: {
      BaseButton,
    },

    props: {
      isDisabled: {
        type: Boolean,
        required: true,
      },
      isEdit: {
        type: Boolean,
        required: true,
      },
      isSaving: {
        type: Boolean,
        required: false,
        default: false,
      },
      maxStep: {
        type: Number,
        required: true,
      },
      step: {
        type: Number,
        required: true,
      },
      hideDefaultButtons: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    methods: {
      goToNextStep() {
        if (this.isLast) return

        this.$emit('nextStep')
      },
      goToPreviousStep() {
        if (this.isFirst) return

        this.$emit('previousStep')
      },
    },

    computed: {
      isFirst() {
        return this.step == 0
      },
      isLast() {
        return this.step == this.maxStep
      },
      buttonProperties() {
        return this.isLast
                ? { text: 'Finish & Save', type: 'submit' }
                : { text: 'Next', type: 'button' }
      },
      showButton() {
        return (this.isEdit || (!this.isEdit && !this.isLast)) && !this.hideDefaultButtons
      },
    },
  }
</script>

<style lang="scss">
  .stepper-actions {
    margin: 2rem 0 0;
  }
</style>
