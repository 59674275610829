<template>
  <div
    class="coaching-content-item"
    :data-testid="dataTestid"
  >
    <simple-tile>
      <div class="coaching-content-item__layout">
        <speech-bubble
          v-if="comment"
          class="coaching-content-item__bubble"
          :imageLink="coachImage"
          :testid="dataTestid"
        >
          {{ comment }}
        </speech-bubble>
        <h2
          class="coaching-content-item__title"
          :data-testid="`${dataTestid}-title`"
        >
          {{ title }}
        </h2>
        <p
          class="coaching-content-item__date"
          :data-testid="`${dataTestid}-date`"
        >
          {{ $t(`${$options.slug}.added`) }}
          {{ createdAt | formatDate(DATE_FORMATS.date) }}
        </p>
        <div
          class="coaching-content-item__description"
          :data-testid="`${dataTestid}-description`"
        >
          {{ description }}
        </div>
        <div class="coaching-content-item__actions">
          <div v-if="isPluginUnavailable">
            {{ $t(`${$options.slug}.unavailable`) }}
          </div>
          <base-button
            v-else
            no-margin
            :data-testid="`${dataTestid}-view-button`"
            v-bind="getViewButtonProps()"
          >
            {{ viewButtonText }}
          </base-button>
        </div>
        <coaching-content-navigation
          :ariaLabel="$t('Content')"
          :id="$route.params.id"
          :testid="dataTestid"
          v-bind="{ isLoading }"
        />
      </div>
    </simple-tile>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'
  import SimpleTile from '@/components/tiles/simple-tile/'

  import { DATE_FORMATS } from '@/constants'

  import testid from '@/mixins/testid/testid'

  import CoachingContentNavigation from './CoachingContentNavigation'

  export default {
    components: {
      BaseButton,
      CoachingContentNavigation,
      SimpleTile,
      SpeechBubble,
    },

    mixins: [
      testid('item'),
    ],

    props: {
      articleLink: {
        type: String,
        required: true,
      },
      createdAt: {
        type: String,
        required: true,
      },
      comment: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      subjectId: {
        type: Number,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      type: {
        type: [Number, String],
        required: true,
      },
      url: {
        type: String,
        required: false,
        default: '',
      },
    },

    created() {
      this.fetchContent({
        userId: this.getCurrentUserId,
        programId: this.currentProgramId,
      })
    },

    data() {
      return {
        DATE_FORMATS,
        isPluginUnavailable: false,
      }
    },

    methods: {
      ...mapActions('coaching', [
        'fetchContent',
      ]),
      getViewButtonProps() {
        const { articleLink, subjectId, type, url } = this

        if (type == 1 || type == 'article') { // article
          return { link: { name: articleLink, params: { id: subjectId } } }
        } else if (type == 2 || type == 'website') { // website
          return { link: url }
        } else if (type == 3 || type == 'plugin') { // plugin
          try {
            return { link: { name: this.getTriggerRouteByPluginId(subjectId) } }
          } catch (err) {
            this.isPluginUnavailable = true
            return null
          }
        }
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('triggers', [
        'getTriggerRouteByPluginId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
      ]),
      isLoading() {
        return this.getLoadingStatesForActions([
          'coaching/fetchContent',
        ])
      },
      viewButtonText() {
        return this.$t(`${this.$options.slug}.${this.type == 3
        ? 'view-plugin' : 'view-article'}`)
      },
    },

    slug: 'component.coaching.coaching-content-item',
  }
</script>

<style lang="scss">
  .coaching-content-item {
    width: 79rem;
    max-width: 100%;
    padding: 1.5rem 0 0;
    @include min-sm {
      padding: 2.7rem;
    }
    &__layout {
      width: 100%;
    }
    &__title {
      margin: 0;
      font-size: 3rem;
      font-weight: 400;
    }
    &__date {
      margin: 1rem 0 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
    &__description {
      margin: 2rem 0 0;
    }
    &__actions {
      margin: 2rem 0 0;
      .button {
        @include max-md {
          font-size: 1.2rem;
        }
      }
    }
    .speech-bubble {
      margin: 0 0 3rem;
    }
    .simple-tile {
      @include min-md {
        padding: 3rem;
      }
    }
  }
</style>
