<template>
  <plugin-article
    :img="'weekly-review/eating.png'">
      <loading-indicator
        v-if="isLoading"/>

      <template
        v-else>
          <template slot="title">
            {{ $t(`${$options.slug}.title`) }}
          </template>
          <template slot="content">
            <p>
              {{ $t(`${$options.slug}.content`) }}
            </p>
            <template
              v-if="form.goals.length">
                <p class="goals-section-intro">
                  {{ $t(`${$options.slug}.intro`) }}
                </p>
                <base-form
                  ref="form"
                  :hasSubmitButton="false"
                  @submit="onSubmit">
                    <grid>
                      <grid-column
                        v-for="(goal, i) in form.goals"
                        :key="i"
                        xs-12
                        md-6>
                          <card-goal
                            :plugin="'Food tracking'"
                            :title="goal.title"
                            :answer="goal.answer"
                            :validation="$options.validation.goal"
                            v-model="goal.rate"/>
                      </grid-column>
                    </grid>
                </base-form>
            </template>
            <weekly-review-no-goals
              v-else
              :type="'Food and Drink'"/>
          </template>
      </template>
  </plugin-article>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  import BaseForm from '@/components/base/BaseForm'
  import CardGoal from '@/components/cards/card-goal/CardGoal'
  import Grid from '@/components/grid/Grid'
  import GridColumn from '@/components/grid/grid-column/GridColumn'
  import LoadingIndicator from '@/components/loading-indicator'
  import PluginArticle from '@/components/plugin/plugin-article/PluginArticle'
  import WeeklyReviewNoGoals from '@/components/weekly-review/WeeklyReviewNoGoals'

  import weeklyReviewSubmit from '@/mixins/weekly-review/weeklyReviewSubmit'

  import eventBus from '@/tools/event-bus'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review-eating.weekly-review-eating.meta.title'),
      }
    },

    components: {
      BaseForm,
      CardGoal,
      Grid,
      GridColumn,
      LoadingIndicator,
      PluginArticle,
      WeeklyReviewNoGoals,
    },

    mixins: [
      weeklyReviewSubmit,
    ],

    props: {
      isLoading: {
        type: Boolean,
        required: true,
      },
    },

    created() {
      this.fetchWeeklyReviewData()
        .then(() => { this.form.goals = [...this.foodGoals] })
    },

    mounted() {
      eventBus.$on('weeklyReview/onNext', this.onSubmit)
    },

    beforeDestroy() {
      eventBus.$off('weeklyReview/onNext')
    },

    data() {
      return {
        form: {
          goals: [],
          step_type: 'eating',
        },
      }
    },

    methods: {
      ...mapActions('weeklyReview', [
        'fetchWeeklyReviewData',
      ]),
    },

    computed: {
      ...mapState('weeklyReview', [
        'foodGoals',
      ]),
    },

    slug: 'views.plugins.weekly-review.weekly-review-eating.weekly-review-eating',

    validation: {
      goal: {
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .goals-section-intro {
    margin-bottom: 2rem;
  }
</style>
