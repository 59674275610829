<template>
  <div
    class="tracking-datepicker"
    :class="{ 'tracking-datepicker--read-only': readOnly }"
  >
    <div class="tracking-datepicker__value">
      <calendar-picker
        v-if="!readOnly"
        ref="calendarPicker"
        auto-close
        no-button
        :id="`data-picker-${_uid}`"
        :name="'trackingDatepicker'"
        :outputFormat="'YYYY-MM-DDTHH:mm:ssZ'"
        v-model="model"
        v-bind="{ format, minDate, maxDate }"
      />
      <div
        class="tracking-datepicker__period"
        v-if="readOnly || period !== 'day'">

        <p
          class="tracking-datepicker__value-text"
          :aria-label="$t('Tracking date:', {date: visibleDateRange })"
        >
          {{ visibleDateRange }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapState } from 'vuex'

  import CalendarPicker from '@/components/forms/calendar-picker-group/CalendarPicker'

  import { DATE_FORMATS } from '@/constants'

  import addVModel from '@/mixins/addVModel'
  import matchMedia from '@/mixins/matchMedia'

  export default {
    components: {
      CalendarPicker,
    },

    mixins: [
      addVModel('model'),
      matchMedia({
        media: 'min-md',
      }),
    ],

    props: {
      endDate: {
        type: [Object, String],
        required: false,
        default: '',
      },
      format: {
        type: String,
        required: false,
        default: DATE_FORMATS.dateTime,
      },
      minDate: {
        type: String,
        required: false,
        default: null,
      },
      maxDate: {
        type: String,
        required: false,
        default: null,
      },
      period: {
        type: String,
        required: true,
      },
      readOnly: {
        type: Boolean,
        required: false,
        default: false,
      },
      startDate: {
        type: [Object, String],
        required: false,
        default: '',
      },
    },

    watch: {
      isStepperContentVisible(value) {
        const options = [
          'hidden',
          'visible',
        ]

        this.onCalendarToggle(options[value])
      },
      visibleDateRange: {
        handler(value) {
          this.$emit('visibleDateRangeChange', value)
        },
        immediate: true,
      },
    },

    data() {
      return {
        isExpanded: false,
      }
    },

    methods: {
      onCalendarToggle(state) {
        this.toggleFixed(state)

        if (state === 'visible') {
          setTimeout(() => this.$refs.pickerButton.focus())
        }
      },
      toggleFixed(state) {
        if (!this.isCalendarFullscreen) return

        document.querySelectorAll('header, footer').forEach(item => {
          item.style.visibility = state
        })
      },
      clickCalendarPicker() {
        setTimeout(() => {
          this.$refs.calendarPicker.$el.querySelector('.calendar-picker input').click()
          this.isExpanded = !this.isExpanded
        })
      },
    },

    computed: {
      ...mapState('layout', [
        'wW',
        'isStepperContentVisible',
      ]),
      visibleDateRange() {
        const end = this.endDate ? moment.parseZone(this.endDate).format(this.dateFormats.end) : ''

        if (this.period === 'day') return end

        const start = this.startDate ? moment.parseZone(this.startDate).format(this.dateFormats.start) : ''

        return `${start} - ${end}`
      },
      isCalendarFullscreen() {
        return this.wW < 416
      },
      dateFormats() {
        const { isMinMd, period } = this

        if (period == 'year') {
          const format = isMinMd ? DATE_FORMATS.monthYear : DATE_FORMATS.monthYearShort
          return { start: format, end: format }
        }

        return {
          start: DATE_FORMATS.dayMonthShort,
          end: DATE_FORMATS.dateShort,
        }
      },
      tabIndex() {
        return this.readOnly ? -1 : 0
      },
    },
  }
</script>

<style lang="scss">
  .tracking-datepicker {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-areas: 'button-prev date-value button-next';
    grid-template-columns: repeat(3, auto);
    align-items: center;
    justify-items: center;
    margin-bottom: 2rem;
    @include min-lg {
      grid-column-gap: 1.6rem;
    }
    &__period {
      display: flex;
    }
    &__value {
      display: flex;
      flex-direction: column;
      align-items: center;
      grid-area: date-value;
      position: relative;
      color: color(dark-primary);
      font-weight: 600;
      transition: opacity 0.4s;
      opacity: 1;
    }
    &__value-text {
      margin: 1px 0 0;
    }
    &__calendar {
      display: flex;
      width: 1.8rem;
      height: 1.8rem;
      margin: 0 0.4rem 0 0;
      svg {
        width: 1.8rem;
        height: 1.8rem;
        fill: color(dark-primary);
      }
    }
    &--read-only {
      pointer-events: none;
      .date-time-picker {
        visibility: hidden;
      }
    }
    .calendar-picker {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .datepicker {
        cursor: default;
      }
      .input-group {
        margin: -2rem 0 0;
        opacity: 0;
        &__input {
          width: auto;
          padding: 0;
          color: color(dark-primary);
          border: none;
        }
        &__icon {
          svg {
            fill: color(dark-primary);
          }
        }
      }
    }
  }
</style>
