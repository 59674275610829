<template>
  <base-table-row>
    <base-table-element
      v-for="(item, i) in items"
      :key="i"
      is-heading-element
      class="heading-element">
        {{ $t(item) }}
    </base-table-element>
  </base-table-row>
</template>

<script>
  import BaseTableElement from '@/components/base/table/element/BaseTableElement'
  import BaseTableRow from '@/components/base/table/row/BaseTableRow'

  export default {
    components: {
      BaseTableElement,
      BaseTableRow,
    },

    props: {
      items: {
        type: Array,
        required: true,
      },
    },
  }
</script>
