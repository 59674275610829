<template>
  <base-main
    background
    data-testid="goals-add-goal"
    :loading="$options.loading">
      <goals-form
        data-testid="goals-add-goal-form"
        v-bind="{ isSaving, profileId }"
        @submit="onSubmit"
      />
  </base-main>
</template>

<script>
  import { isNil } from 'lodash'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import GoalsForm from '@/components/goals/GoalsForm'

  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  export default {
    metaInfo() {
      const { goalTitle } = this

      return {
        title: [
          this.$t('views.goals.add-goal.meta.title'),
          goalTitle,
        ].filter(Boolean).join(' - '),
      }
    },
    components: {
      BaseMain,
      GoalsForm,
    },

    mixins: [
      weightUnitConverter,
    ],

    props: {
      profileId: {
        type: Number,
        required: true,
      },
    },

    created() {
      this.fetchData()
    },

    methods: {
      ...mapActions('goals', [
        'addGoal',
        'fetchPlugin',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      fetchData() {
        if (!this.plugin) {
          this.fetchPlugin({
            triggerDataId: this.getTriggerDataIdByPluginName('goal'),
            userId: this.getCurrentUserId,
          })
        }
      },
      onSubmit(isValid, stepperActions) {
        if (!isValid || this.isSaving) return

        const answers = stepperActions.map(({ token, answers: actionAnswers }) => ({
          token,
          content: Object.values(actionAnswers).filter(answer => !isNil(answer) && answer !== ''),
        }))

        if (this.isWeightGoal && !this.isMetric) {
          if (this.isStonesAndPounds) {
            answers[0].content[0] = this.convertToKgsIfNeeded({
              stones: parseInt(answers[0].content[0], 10),
              pounds: parseInt(answers[0].content[1], 10),
            }).value
          } else {
            answers[0].content[0] = this.convertToKgsIfNeeded(
              parseInt(answers[0].content[0], 10),
            ).value
          }
          answers[0].content = [answers[0].content[0]]
        }

        this.addGoal({
          profile_id: this.profileId,
          user_id: this.getCurrentUserId,
          program_id: this.currentProgramId,
          answers,
        }).then(() => this.$router.push({
          name: 'GoalsView',
          params: {
            welcomeText: this.$t('views.goals.add-goal.success'),
          },
        })).catch(() => this.addSnackbar({ message: this.$t('Something went wrong. Please try again.') }))
      },
    },

    computed: {
      ...mapGetters('goals', [
        'getProfileById',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('triggers', [
        'getTriggerDataIdByPluginName',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'isMetric',
        'isStonesAndPounds',
      ]),
      ...mapState('goals', [
        'plugin',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'goals/addGoal',
        ])
      },
      goalProfile() {
        return this.plugin && this.getProfileById(this.profileId)
      },
      goalTitle() {
        return this.goalProfile?.title
      },
      isWeightGoal() {
        /* eslint-disable-next-line camelcase */
        return this.goalProfile?.template_slug === 'weight_goal'
      },
    },

    loading: [
      'goals/fetchPlugin',
    ],
  }
</script>
