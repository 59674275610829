<template>
  <fieldset class="fieldset-group">
    <legend class="fieldset-group-legend">
      <slot name="legend"/>
    </legend>
    <slot name="content"/>
  </fieldset>
</template>

<script>
  export default {
    inject: {
      $validator: '$validator',
    },
  }
</script>

<style lang="scss">
  .fieldset-group {
    margin: 1rem 0;
    &-legend {
      margin-bottom: 0.2rem;
      color: color(dark-primary);
      font-weight: 600;
    }
  }
</style>
