<script>
  import LoadingIndicator from '@/components/loading-indicator'
  import SimpleTile from '@/components/tiles/simple-tile'

  import { bemConstructor, hasSlot } from '@/helpers'

  export default {
    functional: true,

    props: {
      headerText: {
        type: String,
        required: false,
        default: '',
      },
      isFetchingChartData: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    render(h, { props, slots, data }) {
      const { 'data-testid': dataTestid } = data.attrs
      const bem = bemConstructor('tracking-dashboard')
      const getTestId = slug => ['tracking-dashboard', slug]
        .filter(Boolean)
        .join('-')

      /* eslint-disable operator-linebreak */
      return (
        <SimpleTile
          data-testid={`${dataTestid} ${getTestId()}`}
        >
          <div class={bem()}>
            <div class={bem('actions')}>
              {
                hasSlot(slots, 'log-button') &&
                  <div class={`${bem('log', 'desktop')}`}>
                    {slots()['log-button']}
                  </div>
              }
              <div class={bem('main')}>
                {
                  props.headerText &&
                    <h1
                      class={bem('heading')}
                      data-testid={getTestId('heading')}
                    >
                      {props.headerText}
                    </h1>
                }
                {
                  hasSlot(slots, 'log-button') &&
                    <div class={`${bem('log', 'mobile')}`}>
                      {slots()['log-button']}
                    </div>
                }
                {slots()['tracking-datepicker']}
              </div>
              <div class={bem('secondary-actions')}>
                {slots()['secondary-actions']}
              </div>
            </div>
            <div class="container-radio-switcher">
              {slots()['radio-switcher']}
            </div>
            {
              props.isFetchingChartData &&
                <div class={bem('loading')}>
                  <LoadingIndicator relative/>
                </div>
            }
            {
              !props.isFetchingChartData &&
                <div>
                  <div>
                    {slots().chart}
                    {slots()['info-text']}
                    {slots()['chart-details']}
                  </div>
                  {
                    hasSlot(slots, 'chart-accessibility') &&
                      <div class={`${bem('log', 'chart-accessibility')}`}>
                        { slots()['chart-accessibility'] }
                      </div>
                  }
                </div>
            }
          </div>
        </SimpleTile>
      )
      /* eslint-enable operator-linebreak */
    },
  }
</script>

<style lang="scss">
  .tracking-dashboard {
    width: 100%;
    margin: 0 auto;
    @include min-lg {
      flex-grow: 1;
    }
    &__actions {
      @include min-lg {
        display: grid;
        grid-template-columns: 25% 50% 25%;
        padding: 0 0 2rem;
      }
    }
    &__log {
      &--desktop {
        display: none;
        @include min-lg {
          display: block;
        }
      }
      &--mobile {
        display: block;
        margin: 1rem 0 0;
        @include min-lg {
          display: none;
        }
      }
    }
    &__main {
      justify-self: center;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      @include min-lg {
        margin: 0;
      }
    }
    &__heading {
      margin: 0.5rem 0 0;
      font-size: 1.8rem;
      font-weight: 800;
      @include min-lg {
        font-size: 2.2rem;
      }
    }
    &__secondary-actions {
      display: flex;
      flex-direction: column;
      align-self: end;
      justify-content: space-between;
      justify-self: end;
      height: 100%;
      .button {
        min-width: 8.8rem;
      }
    }
    &__loading {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20rem;
    }
    &__accessibility {
      position: absolute;
      left: -999rem;
      top:-999rem;
      overflow: hidden;
    }
    .container-radio-switcher {
      display: flex;
      .tracking__today {
        border-bottom: solid 1px color(primary);
      }
    }
  }
</style>
