<template>
  <base-main
    class="weekly-review"
    :loading="$options.loading"
  >
    <container
      first
      flex-column
      plugin-article
    >
      <router-view
        v-bind="props"
      />

      <div class="weekly-review-actions">
        <template
          v-if="!isCompleted"
        >
          <base-button
            v-if="routes.previous && !hasSummary"
            :link="{ name: routes.previous.route }"
          >
            {{ $t('Back') }}
          </base-button>

          <base-button
            data-testid="weekly-review-next"
            :link="nextButton.link"
            :disabled="isLoading || isSaving"
            :isLoading="isLoading || isSaving"
            @click="onNext"
          >
            {{ $t(nextButton.text) }}
          </base-button>
        </template>

        <template
          v-else
        >
          <base-button
            v-if="summary.length"
            id="change-goals-button"
            :link="{ name: 'GoalsView' }"
          >
            {{ $t(`${$options.slug}.goals-button`) }}
          </base-button>

          <base-button
            id="finish-weekly-review-button"
            :link="{ name: 'Tools' }"
          >
            {{ $t('Finish') }}
          </base-button>
        </template>
      </div>
    </container>
  </base-main>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import {
    BaseButton,
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'

  import routes from '@/data/weekly-review/weeklyReviewRoutes'

  import eventBus from '@/tools/event-bus'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.weekly-review.weekly-review.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
    },

    created() {
      eventBus.$on('weeklyReview/goToNextView', this.goToNextView)
    },

    beforeDestroy() {
      eventBus.$off('weeklyReview/goToNextView', this.goToNextView)
    },

    data() {
      return {
        skipFeedbackMessage: false,
      }
    },

    methods: {
      onNext() {
        eventBus.$emit('weeklyReview/onNext')
      },
      isInWeeklyReviewSteps(steps) {
        return this.stepsArray.some(item => steps?.includes(item))
      },
      hasFeedbackMessage(feedback) {
        if (this.skipFeedbackMessage) return false

        if (Array.isArray(feedback)) {
          return feedback.find(item => this.getFeedbackMessage(item))
        }

        return this.getFeedbackMessage(feedback)
      },
      goToNextView(skipFeedbackMessage) {
        this.skipFeedbackMessage = skipFeedbackMessage

        this.$router.push({ name: this.routes.next.route })
      },
      getPrevRoute(i) {
        return routes
          .filter((route, index) => index < i)
          .reverse()
          .find(route => ((this.hasFeedbackMessage(route.feedback))
            || this.isInWeeklyReviewSteps(route.step)))
      },
      getNextRoute(i) {
        return routes
          .filter((route, index) => index > i)
          .find(route => ((this.hasFeedbackMessage(route.feedback))
            || this.isInWeeklyReviewSteps(route.step)))
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('weeklyReview', [
        'getFeedbackMessage',
      ]),
      ...mapState('weeklyReview', [
        'hasSummary',
        'stepsArray',
        'summary',
      ]),
      isCompleted() {
        return this.$route.name == 'WeeklyReviewCompleted'
      },
      isLoading() {
        return this.getLoadingStatesForActions([
          ...this.$options.loading,
        ])
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'weeklyReview/addImage',
          'weeklyReview/saveWeeklyReviewData',
        ])
      },
      nextButton() {
        return {
          link: this.routes.current.hasForm ? null : { name: this.routes.next.route },
          text: this.routes.current.buttonText || 'Next',
        }
      },
      props() {
        const { isLoading } = this
        return this.routes.current.loading ? { isLoading } : {}
      },
      routes() {
        const { name } = this.$route
        const i = routes.findIndex(({ route }) => route == name)

        return {
          current: routes[i],
          next: this.getNextRoute(i) || routes[routes.length - 1],
          previous: this.getPrevRoute(i) || null,
        }
      },
    },

    beforeRouteEnter(to, from, next) {
      next(vm => {
        const { token } = vm.$store._modules.root.state.weeklyReview

        if (!token) {
          next({ name: 'WeeklyReviewIntro', replace: true })
        }
      })
    },

    slug: 'views.plugins.weekly-review.weekly-review',

    loading: [
      'weeklyReview/fetchWeeklyReviewData',
      'weeklyReview/fetchInitWeeklyReviewData',
    ],
  }
</script>

<style lang="scss">
  .weekly-review {
    &-actions {
      align-self: flex-end;
      margin: 2rem 0 0;
    }
  }
</style>
