<script>
  import { cloneDeep } from 'lodash'
  import { mapGetters } from 'vuex'

  import { ContentNavigation } from '@/components/content-navigation'

  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      testid('coaching-content-navigation'),
    ],

    props: {
      ariaLabel: {
        type: String,
        default: '',
      },
      id: {
        type: [String, Number],
        required: true,
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    render() {
      const previous = this.swapItemId(this.getItemOffset(this.id, -1))
      const next = this.swapItemId(this.getItemOffset(this.id, 1))

      return (
        <ContentNavigation
          ariaLabel={this.ariaLabel}
          component='CoachingPluginShowContent'
          isLoading={this.isLoading}
          next={next?.item}
          testid={this.dataTestid}
          previous={previous?.item}
        />
      )
    },

    methods: {
      swapItemId(article) {
        if (!article) return

        const _article = cloneDeep(article)

        _article.item.id = _article.id

        return _article
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'getItemOffset',
      ]),
    },
  }
</script>
