<template>
  <component
    :is="isHeadingElement ? 'th' : 'td'"
    class="base-table-element"
    :class="{ 'base-table-element--is-highlighted': isHighlighted }"
    v-bind="{ colspan }"
  >
    <slot/>
  </component>
</template>

<script>
  export default {
    props: {
      colspan: {
        type: Number,
        required: false,
        default: 1,
      },
      isHeadingElement: {
        type: Boolean,
        required: false,
        default: false,
      },
      isHighlighted: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
  .base-table-element {
    max-width: 30rem;
    padding: 1rem 1rem;
    &--is-highlighted {
      background-color: color(primary);
      color: color(light-primary)
    }
  }
  th.base-table-element {
    color: color(secondary-text);
    font-weight: 500;
  }
</style>
