<script>
  import 'chartjs-plugin-annotation'
  import { Bar, mixins } from 'vue-chartjs'

  const { reactiveProp } = mixins

  export default {
    inheritAttrs: false,

    extends: Bar,

    mixins: [
      reactiveProp,
    ],

    props: {
      chartData: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      options: {
        type: Object,
        required: false,
        default: () => ({}),
      },
    },

    mounted() {
      this.renderChart(this.chartData, this.options)
    },
  }
</script>
