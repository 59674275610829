<template>
  <tr
    class="base-table-row"
    :class="{
      'base-table-row--is-clickable': isClickable,
    }"
  >
    <slot/>
  </tr>
</template>

<script>
  export default {
    props: {
      isClickable: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
  .base-table-row {
    border: 1px solid color(primary);
    td, th {
      border: 1px solid color(primary);
    }
    &--is-clickable {
      &:hover {
        background-color: color(light-primary);
        cursor: pointer;
      }
    }
  }
</style>
