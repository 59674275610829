<template>
  <base-form
    class="stepper-form"
    :hasSubmitButton="false"
    v-slot="{ errors }"
    @submit="onSubmit">
      <stepper
        :validationErrors="errors"
        :slots="$slots">
          <slot/>
      </stepper>
  </base-form>
</template>

<script>
  import BaseForm from '@/components/base/BaseForm'
  import Stepper from '@/components/stepper/Stepper'

  export default {
    components: {
      BaseForm,
      Stepper,
    },

    methods: {
      onSubmit(isValid) {
        this.$emit('submit', isValid)
      },
    },
  }
</script>

<style lang="scss">
  .stepper-form {
    margin: 2rem 0 0;
    justify-content: space-between;
    align-items: flex-end;
  }
</style>
