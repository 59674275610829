<template>
  <div
    class="stepper-step"
    :class="[
      { 'stepper-step--is-current': isCurrent },
      { 'stepper-step--is-disabled': isDisabled },
      { 'stepper-step--is-last': isLast },
      { 'stepper-step--is-next': isNext },
      { 'stepper-step--is-previous': isPrevious },
      { 'stepper-step--has-errors' : hasErrors },
      { 'stepper-step--show-overflow' : showOverflow },
    ]"
    data-testid="stepper-old-step"
  >
    <stepper-icons
      :step="step + 1"
      v-bind="{ hasErrors, isCurrent, isEdit, isNext, isPrevious }"
    />

    <div class="stepper-container">
      <h2
        class="stepper-label"
        :aria-controls="`stepper-content-${uuid}`"
        :aria-expanded="isCurrent.toString()"
        data-testid="stepper-old-step-label"
        tabindex="0"
        @click="onClick"
        @keydown.prevent.enter.space="onClick">
          <slot name="label"/>
          <transition-fade>
            <inline-svg
              v-if="isEdit && isPrevious"
              class="stepper-edit"
              src="pencil.svg"
              aria-label="Edit"
            />
          </transition-fade>
      </h2>
      <div
        class="stepper-content"
        data-testid="stepper-old-content"
        :id="`stepper-content-${uuid}`"
        :aria-hidden="(!isCurrent).toString()"
      >
        <div>
          <slot name="content"/>
        </div>

        <stepper-actions
          :isDisabled="isDisabled || isNextDisabled"
          v-bind="{ hideDefaultButtons, isEdit, isSaving, maxStep, step }"
          @nextStep="onNextStep"
          @previousStep="onPreviousStep"
        />
      </div>
    </div>
    <div class="stepper-line-container">
      <div class="stepper-line"/>
    </div>
  </div>
</template>

<script>
  import StepperActions from '@/components/stepper/stepper-actions/StepperActions'
  import StepperIcons from '@/components/stepper/stepper-icons/StepperIcons'

  import TransitionFade from '@/transitions/transition-fade/TransitionFade'

  export default {
    inheritAttrs: false,

    components: {
      StepperActions,
      StepperIcons,
      TransitionFade,
    },

    props: {
      fieldsNames: {
        type: [Array, String],
        required: false,
        default: () => [],
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      isNextDisabled: {
        type: Boolean,
        default: false,
        required: false,
      },
      isEdit: {
        type: Boolean,
        required: false,
        default: true,
      },
      isSaving: {
        type: Boolean,
        required: false,
        default: false,
      },
      showOverflow: {
        type: Boolean,
        required: false,
        default: false,
      },
      hideDefaultButtons: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    beforeMount() {
      this.step = this.$parent.$children
                    // filters out v-portal as it shouldn't be counted in,
                    // v-portal is being added only on ios
                    .filter(({ $el }) => !$el?.classList.contains('v-portal'))
                    .length - 1
    },

    watch: {
      isCurrent: {
        handler(value) {
          if (!value) return

          const animationDuration = 1200
          const tiemout = setTimeout(() => {
          this.$emit('step-activated')
          }, animationDuration)

          this.$once('hook:beforeDestroy', () => {
            clearTimeout(tiemout)
          })
        },
        immediate: true,
      },
      /* eslint-disable-next-line object-shorthand */
      '$parent.currentStep'(newValue) {
        this.currentStep = newValue
      },
      /* eslint-disable-next-line object-shorthand */
      '$parent.maxStep'(newValue) {
        this.maxStep = newValue
      },
      /* eslint-disable-next-line object-shorthand */
      '$parent.validationErrors'(newValue) {
        this.validationErrors = newValue
      },
    },

    data() {
      return {
        currentStep: this.$parent.currentStep,
        maxStep: this.$parent.maxStep,
        step: null,
        validationErrors: {},
      }
    },

    methods: {
      onClick() {
        if (this.isDisabled) return

        this.$emit('setStep', this.step)
      },
      onNextStep() {
        this.$emit('nextStep')
      },
      onPreviousStep() {
        this.$emit('previousStep')
      },
    },

    computed: {
      isCurrent() {
        return this.currentStep == this.step
      },
      isLast() {
        return this.step == this.maxStep
      },
      isNext() {
        return this.currentStep < this.step
      },
      isPrevious() {
        return this.currentStep > this.step
      },
      hasErrors() {
        const fields = Array.isArray(this.fieldsNames) ? this.fieldsNames : [this.fieldsNames]
        return fields.some(field => this.validationErrors[field]?.length)
      },
    },
  }
</script>

<style lang="scss">
  .stepper {
    &-step {
      display: flex;
      margin: 0.8rem 0 0 0;
      padding: 0 0 3rem 0;
      position: relative;
      &:first-of-type {
        margin: 0;
      }
      &:last-of-type:not(.stepper-step--is-current) {
        &:before {
          height: 0;
        }
      }
      &:before {
        content: '';
        display: flex;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 1.7rem;
        transition: height 0.4s ease-out;
        background-color: color(grey);
      }
      &--is-current {
        &:before {
          background-color: color(primary);
        }
        .stepper-label {
          font-size: 1.6rem;
          cursor: auto;
          @media all and (min-width: map-get($breakpoints, sm)) {
            font-size: 1.9rem;
          }
        }
        .stepper-content {
          max-height: 100rem;
          visibility: visible;
          transition: max-height 0.8s ease-in 0.4s, overflow 0s 1.2s;
        }
      }
      &--is-disabled:not(.stepper-step--is-current) {
        .stepper-label {
          cursor: not-allowed;
        }
      }
      &--is-last {
        &:before {
          display: none;
        }
      }
      &--is-next {
        .stepper-label {
          color: color(grey);
        }
      }
      &--has-errors {
        .stepper-label {
          color: color(stepper-error);
        }
      }
      &--show-overflow.stepper-step--is-current {
        .stepper-content {
          overflow: visible;
        }
      }
    }
    &-container {
      width: 100%;
      margin: 0 0 0 1.2rem;
    }
    &-label {
      display: flex;
      align-items: center;
      min-height: 3rem;
      color: color(primary-text);
      font-size: 1.4rem;
      letter-spacing: 0.02rem;
      cursor: pointer;
      transition: color 0.2s ease-in-out;
      @media all and (min-width: map-get($breakpoints, sm)) {
        min-height: 3.5rem;
        font-size: 1.7rem;
      }
    }
    &-edit {
      display: flex;
      width: 1.8rem;
      height: 2.3rem;
      margin: 0 0 0 1.2rem;
      svg {
        fill: color(stepper-pencil);
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      max-height: 0;
      visibility: hidden;
      overflow: hidden;
      transition: max-height 0.4s ease-out;
    }
    &-additional {
      margin: 1.3rem 0 0.5rem 0;
      color: color(secondary-text);
      &--dark {
        color: color(primary-text);
      }
    }
    .textarea-group {
      padding: 1.3rem 0 0 0;
    }
  }
</style>
