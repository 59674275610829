<template>
  <div
    class="feedback"
    data-testid="footer-feedback"
  >
    <inline-svg
      ref="feedbackIcon"
      aria-label="Feedback Icon"
      class="feedback__icon"
      role="presentation"
      src="icons/icon-chat.svg"
    />

    How can we improve?
    <button
      class="feedback__link"
      data-testid="footer-feedback-button"
      @click="openFeedback"
    >
       Give us feedback on this page
    </button>
  </div>
</template>

<script>
  import { PAGE_TYPES } from '@/constants'

  export default {
    mounted() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.feedbackIcon.$el?.querySelector('svg')?.setAttribute('aria-label', 'Feedback Icon')
    },

    methods: {
      openFeedback() {
        const nameView = this.$route.meta.type === PAGE_TYPES.auth ? 'FeedbackAuthView' : 'FeedbackView'
        this.$router.push({ name: nameView })
      },
    },
  }
</script>

<style lang="scss">
  .feedback {
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      width: 2rem;
      margin-right: 0.7rem;
    }
    &__link {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
</style>
