<template>
  <base-main
    :loading="$options.loading"
    :data-testid="'coaching-plugin'"
  >
    <div class="coaching">

      <template v-if="coach">
        <portal-target
          :name="'sub-tabs'"
        />
        <simple-tile>
          <div class="coaching__book-appointment">
            <base-button
              no-margin
              data-testid="coaching-plugin-book-appointment-button"
              :link="{ name: 'CoachingPluginNewAppointment' }"
            >
              {{ $t('Book appointment') }}
            </base-button>
            <span
              data-testid="coaching-plugin-remaining-appointments"
            >
              {{ appointmentsRemainingText }}
              {{ $tc('appointment', remainingAppointments) }}
            </span>
          </div>
        </simple-tile>
      </template>

      <div class="coaching__your-coach">
        <h1 class="coaching__header">
          {{ $t(`${$options.slug}.coach`) }}
        </h1>
        <simple-tile>
          <div class="your-coach__layout">
            <div
              v-if="coachImage"
              class="your-coach__photo"
            >
              <img
                alt=""
                :src="coachImage"
              >
            </div>
            <div class="your-coach__profile">
              <h3
                class="your-coach__name"
                data-testid="coaching-plugin-coach-name"
              >
                {{ $t(coachName) }}
              </h3>
              <p
                v-if="coachTitle"
                class="your-coach__title"
                data-testid="coaching-plugin-coach-title"
              >
                {{ $t(coachTitle) }}
              </p>
              <p
                v-if="coachDescription"
                class="your-coach__description"
                data-testid="coaching-plugin-coach-description"
              >
                {{ coachDescription }}
              </p>
              <base-button
                v-if="coach"
                no-margin
                class="your-coach__link"
                data-testid="coaching-plugin-coach-view-profile"
                :link="{ name: 'CoachingPluginCoach' }"
                @click.native="coachingProfileViewed"
              >
                {{ $t(`${$options.slug}.action-button`) }}
              </base-button>
            </div>
          </div>
        </simple-tile>
      </div>

      <template v-if="hasAppointments">

        <div
          v-for="(type, i) in appointmentsTypes"
          :key="`type${i}`"
          class="coaching__appointment-type"
        >
          <div
            class="appointment-type"
            data-testid="coaching-plugin-appointment-type"
          >
            <h2 class="coaching__header">
              {{ $t(type.title) }}
            </h2>
            <simple-tile
              v-for="(appointment, j) in type.appointments"
              :key="`appointment${j}`"
              :dark="type.isUpcoming"
            >
              <div class="appointment__layout">
                <div
                  :id="`appointment-time-${uuid}-${j}`"
                  class="appointment__info"
                >
                  <div class="appointment__date-layout">
                    <h3
                      class="appointment__date"
                      :class="{ 'appointment__date--cancelled': appointment.cancelled }"
                      data-testid="coaching-plugin-appointment-date"
                    >
                      {{ appointment.since | formatDate(DATE_FORMATS.date) }}
                    </h3>
                    <span
                      v-if="appointment.cancelled"
                      class="appointment__cancelled"
                      data-testid="coaching-plugin-appointment-canceled"
                    >
                      {{ $t('(Cancelled)') }}
                    </span>
                  </div>

                  <p
                    class="appointment__time"
                    data-testid="coaching-plugin-appointment-time"
                  >
                    {{ extractCallTime(appointment.timedata.call) }}
                  </p>
                </div>
                <base-button
                  v-if="!appointment.cancelled"
                  no-margin
                  class="appointment__link"
                  data-testid="coaching-plugin-appointment-view-button"
                  :aria-describedby="`appointment-time-${uuid}-${j}`"
                  :link="{
                    name: 'CoachingPluginShowAppointment',
                    params: { id: appointment.id },
                  }"
                >
                  {{ $t('View appointment') }}
                </base-button>
              </div>
            </simple-tile>
          </div>
        </div>
      </template>
    </div>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import SimpleTile from '@/components/tiles/simple-tile'

  import { DATE_FORMATS } from '@/constants'

  import pluralize from '@/filters/pluralize'

  import requireImage from '@/mixins/requireImage'
  import unblockPlugin from '@/mixins/unblockPlugin'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.coaching-plugin.coaching-plugin.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      SimpleTile,
    },

    filters: {
      pluralize,
    },

    mixins: [
      requireImage,
      unblockPlugin,
    ],

    created() {
      this.unblockPlugin()

      if (this.preferences) return

      this.fetchPreferences(this.getCurrentUserId)
        .catch(() => (
          this.$router.replace({ name: 'CoachingPluginStart', params: { fromRedirect: true } })
        ))
    },

    watch: {
      preferences: {
        handler() {
          if (!this.preferences) return

          this.fetchCoach({
            programId: this.currentProgramId,
            userId: this.getCurrentUserId,
            silence: true,
          })
            .then(() => this.fetchAppointments({
              coachId: this.coach.id,
              userId: this.getCurrentUserId,
              silence: true,
            }))
            .catch(() => {})
        },
        immediate: true,
      },
    },

    data() {
      return {
        DATE_FORMATS,
      }
    },

    methods: {
      ...mapActions('coaching', [
        'fetchAppointments',
        'fetchCoach',
        'fetchPreferences',
      ]),
      coachingProfileViewed() {
        this.$mam.event('Books coach - coach profile viewed', {
           coach_id: this.coach.user.ppuid,
           coach_name: this.coach.name,
        })
      },
      extractCallTime({ duration, time }) {
        const start = moment(time).utcOffset(this.getTimezoneOffset, true)
        const end = moment(start).add(duration, 'minutes')
        const format = 'HH:mm'

        return `${start.format(format)} - ${end.format(format)}`
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
        'getAppointmentsByType',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'getTimezoneOffset',
      ]),
      ...mapState('coaching', [
        'coach',
        'preferences',
        'stats',
      ]),
      appointmentsTypes() {
        return this.getAppointmentsByType?.filter(i => i.appointments.length)
      },
      coachName() {
        return this.coach
                ? this.coach.name
                : 'Unassigned'
      },
      coachTitle() {
        return this.coach
                ? this.coach.title
                : `${this.$options.slug}.unassigned`
      },
      coachDescription() {
        return this.coach?.description
          ? this.coach.description.split('\n')[0]
          : ''
      },
      hasAppointments() {
        if (!this.appointmentsTypes) return

        return this.appointmentsTypes.some(type => type.appointments.length)
      },
      hasFab() {
        return Boolean(this.coach) && Boolean(this.remainingAppointments)
      },
      remainingAppointments() {
        if (!this.stats) return

        const remaining = this.stats.max - this.stats.done
        return remaining > 0 ? remaining : 0
      },
      appointmentsRemainingText() {
        return this.$t(`${this.$options.slug}.remaining-appointments`, {
          appointments: this.remainingAppointments,
        })
      },
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin',

    loading: [
      'coaching/fetchPreferences',
    ],
  }
</script>

<style lang="scss">
.coaching {
  width: 100%;
  max-width: 77rem;
  line-height: 2rem;
  a.sub-tab:visited:not(:hover) {
    color: color(primary);
  }
  a.sub-tab--active:visited:not(:hover) {
    color: color(_white);
  }
  a.sub-tab--active:hover {
    color: color(button-hover-text);
  }
  &__book-appointment {
    display: grid;
    grid-row-gap: 1rem;
    width: 100%;
    text-align: center;
    @include min-md {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }
  }
  &__your-coach {
    width: 100%;
  }
  &__appointment-type {
    width: 100%;
    .appointment-type {
      padding-bottom: 2rem;
    }
  }
  &__header {
    margin: 3rem 0 2rem;
  }
}
.your-coach {
  &__layout {
    display: flex;
    flex-direction: column;
    @include min-md {
      flex-direction: row;
    }
  }
  &__photo {
    width: 18rem;
    min-width: 18rem;
    height: 18rem;
    margin: 0 auto;
    border-radius: 9rem;
    @include min-md {
      margin: 0 4rem 0 0;
    }
  }
  &__name {
    margin: 1rem 0 0;
    font-size: 2.2rem;
    font-weight: 800;
    @include min-md {
      margin: 0;
    }
  }
  &__title {
    margin: 1rem 0 0;
    color: color(primary);
    font-size: 1.8rem;
    font-weight: 800;
  }
  &__description {
    margin: 2rem 0 0;
    font-size: 1.4rem;
    line-height: inherit;
  }
  &__link {
    margin: 2rem 0 0;
  }
}
.appointment {
  &__layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include min-md {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__date {
    display: inline-block;
    margin: 0;
    font-weight: 600;
    &--cancelled {
      text-decoration: line-through;
    }
  }
  &__cancelled {
    color: color(_white);
    font-size: 1.4rem;
    font-weight: 400;
  }
  &__time {
    margin: 1rem 0 0;
  }
  &__link {
    margin: 2rem auto 0 0;
    @include min-md {
      margin: 0;
    }
  }
}
</style>
