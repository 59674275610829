<template>
  <base-main
    data-testid="glucose-tracking-log"
    :loading="$options.loading"
  >
    <container
      first
    >
     <stepper-custom-form
        ref="form"
        data-testid="glucose-tracking-log-form"
        :buttonLabels="{ finishLabel: submitText }"
        :isLoading="isSaving"
        @cancel="goBack"
        @submit="onSubmit"
      >
        <template #default>
          <stepper-custom-step
            v-for="(formField, i) in formFields"
            :key="i"
            data-testid="glucose-tracking-log-stepper-custom-step"
            :title="titleFormField(formField)"
          >
          <div
            v-if="formField"
            class="log-glucose__content"
            data-testid="glucose-tracking-log-content"
            >
                <form-fields-generator-field
                :dataModel="form"
                v-bind="{ field: formField }"
                />
            </div>
          </stepper-custom-step>
        </template>
     </stepper-custom-form>
    </container>
  </base-main>
</template>

<script>
  import { invert } from 'lodash'
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'
  import FormFieldsGeneratorField from '@/components/forms/form-fields-generator/FormFieldsGeneratorField'

  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import {
    BLOOD_READING_TIMES,
    BLOOD_READING_TYPES,
    DATE_FORMATS,
    GLUCOSE_UNITS,
    HBA1C_UNITS,
  } from '@/constants'
  import goBack from '@/mixins/goBack'

  import i18n from '@/mixins/i18n'
  import requireImage from '@/mixins/requireImage'
  import testid from '@/mixins/testid/testid'

  const baseTestid = 'glucose-tracking-log'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.food-tracking.food-tracking.log-or'),
      }
    },
    components: {
      FormFieldsGeneratorField,
      BaseMain,
      Container,
      StepperCustomForm,
      StepperCustomStep,
    },

    mixins: [
      i18n,
      testid(baseTestid),
      requireImage,
      goBack,
    ],

    created() {
      this.date = this.$route.query.date
      this.id = this.$route.query.id
      this.readingValue = this.$route.query.readingValue
      this.time = this.$route.query.time
      this.type = this.$route.query.type
      if (this.isEdit()) {
        this.fillForm()
      } else {
        const now = moment()
        this.form.date = now.format(DATE_FORMATS.dateShort)
        this.form.hour = now.format(DATE_FORMATS.time)
      }
    },

    watch: {
      /* eslint-disable-next-line object-shorthand */
      'form.type'() {
        if (this.isEdit()) return

        this.form.value = null
      },
    },

    data() {
      return {
        form: {
          type: BLOOD_READING_TYPES.glucose,
          date: '',
          hour: '',
          time: Object.keys(BLOOD_READING_TIMES)[0],
          value: null,
        },
      }
    },

    methods: {
      ...mapActions('glucoseTracking', [
        'saveReading',
        'updateReading',
        'deleteReading',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        const reading = {
          isGlucose: this.isGlucose,
          timestamp: this.timestamp,
          type: this.form.type,
          value: this.form.value,
          ...(this.form.time ? { time: BLOOD_READING_TIMES[this.form.time] } : null),
        }

        if (this.isEdit()) {
          this.updateReading({
            reading,
            id: this.id,
          }).finally(this.finalize)
        } else {
          this.saveReading(reading)
            .finally(this.finalize)
        }
      },
      fillForm() {
        this.form = {
          date: moment
            .parseZone(this.date)
            .format(DATE_FORMATS.dateShort),
          hour: moment
            .parseZone(this.date)
            .format(DATE_FORMATS.time),
          time: invert(BLOOD_READING_TIMES)[this.time],
          type: this.type,
          value: this.readingValue,
        }
      },
      finalize() {
        this.addSnackbar({ message: this.$t('Glucose Tracking log saved') })
        this.goBack()
      },
      titleFormField(formField) {
        return formField?.props?.legend || formField?.props?.label || ''
      },
      isEdit() {
        return !!this.id
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('glucoseTracking', [
        'settings',
      ]),
      ...mapGetters('user', [
        'getTimezoneOffset',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'glucoseTracking/logReading',
          'glucoseTracking/updateReading',
        ])
      },
      formFields() {
        return [
          ...(this.isEdit() ? [null] : this.typeFields),
          this.dateField,
          ...(this.isGlucose ? this.glucoseFields : [null]),
          this.valueField,
        ].filter(item => item !== null)
      },
      valueField() {
        return {
          type: 'InputGroup',
          name: 'value',
          class: 'log-glucose__form-field',
          props: {
            afterText: this.unit,
            hasHiddenAsterisk: true,
            label: this.i18n('value'),
            step: this.unitStep,
            type: 'number',
            validation: {
              required: true,
              min_value: this.unitMinValue,
              max_value: this.unitMaxValue,
              decimal: [this.unitDecimalPlaces, '.'],
            },
          },
        }
      },
      dateField() {
        /* eslint-disable camelcase */
        return {
          type: 'CalendarPickerGroup',
          class: 'log-glucose__form-field',
          name: 'date',
          props: {
            format: DATE_FORMATS.dateShort,
            formatted: DATE_FORMATS.date,
            hasHiddenAsterisk: true,
            label: 'Select the date of your reading',
            maxDate: moment
              .utc()
              .utcOffset(this.getTimezoneOffset)
              .format('YYYY-MM-DD 23:59'),
            minDate: moment
              .utc(this.settings?.created_at, 'YYYY-MM-DD HH:mm:ss')
              .utcOffset(this.getTimezoneOffset)
              .format('YYYY-MM-DD 00:00'),
            testid: this.dataTestid,
            outputFormat: DATE_FORMATS.dateShort,
            validation: { required: true },
          },
        }
        /* eslint-enable camelcase */
      },
      unit() {
        /* eslint-disable camelcase */
        return this.isGlucose
          ? GLUCOSE_UNITS[this.settings?.glucose_units]
          : HBA1C_UNITS[this.settings?.hba1c_units]
        /* eslint-enable camelcase */
      },
      isGlucose() {
        return this.form.type === BLOOD_READING_TYPES.glucose
      },
      isHba1c() {
        return this.form.type === BLOOD_READING_TYPES.hba1c
      },
      isMgDl() {
        return this.unit === GLUCOSE_UNITS[1]
      },
      isMmolL() {
        return this.unit === GLUCOSE_UNITS[2]
      },
      isPercentage() {
        return this.unit === HBA1C_UNITS[1]
      },
      isMmolMol() {
        return this.unit === HBA1C_UNITS[2]
      },
      unitDecimalPlaces() {
        return this.isMgDl || this.isMmolMol ? 0 : 1
      },
      unitMinValue() {
        return this.isPercentage ? 2.2 : 0
      },
      unitMaxValue() {
        return this.isGlucose
          ? this.isMgDl
            ? 10000
            : 556
          : this.isMmolMol
            ? 10000
            : 918
      },
      unitStep() {
        return this.unitDecimalPlaces === 1 ? 0.1 : 1
      },
      timestamp() {
        const date = moment(this.form.date)
        // const date = moment
        //   .utc(this.form.date, DATE_FORMATS.dateShort)
        //   .utcOffset(this.getTimezoneOffset, true)
        const hour = moment
          .utc(this.form.hour, 'HH:mm')
          .utcOffset(this.getTimezoneOffset, true)

        if (this.isGlucose) {
          date.set({
            hour: hour.hour(),
            minute: hour.minutes(),
          })
        }

        return date
      },
      submitText() {
        return this.isEdit() ? 'Edit reading' : 'Log reading'
      },
      headerText() {
        return this.isEdit()
          ? this.isGlucose
            ? 'Edit blood glucose'
            : 'Edit HbA1c'
          : 'Log blood glucose'
      },
      hourPickerPosition() {
        return this.isMinMd ? null : 'top'
      },
      typeFields() {
        return [{
          type: 'RadioMultiGroup',
          name: 'type',
          class: 'log-glucose__radio',
          props: {
            legend: this.i18n('reading-type'),
            labelKey: 'label',
            options: [
              { value: BLOOD_READING_TYPES.glucose, label: this.$t('Blood glucose') },
              { value: BLOOD_READING_TYPES.hba1c, label: this.$t('HbA1c') },
            ],
            testid: baseTestid,
            validation: { required: true },
            valueKey: 'value',
          },
        }]
      },
      glucoseFields() {
        return [{
          type: 'HourPickerGroup',
          name: 'hour',
          class: 'log-glucose__form-field',
          props: {
            label: this.i18n('time-label'),
            format: 'HH:mm',
            testid: baseTestid,
            validation: { required: true },
          },
        }, {
          type: 'SelectGroup',
          name: 'time',
          class: 'log-glucose__form-field',
          props: {
            hasHiddenAsterisk: true,
            label: this.i18n('time-of-day-label'),
            options: Object.keys(BLOOD_READING_TIMES),
            testid: baseTestid,
            validation: { required: true },
          },
        }]
      },
    },

    slug: 'views.plugins.glucose-tracking.glucose-tracking-log',
  }
</script>

<style lang="scss">
  .log-glucose {
    &__content {
      width: 100%;
    }
    &__radio {
      margin: 0 0 -1rem;
    }
    &__form-field {
      margin: 1.8rem 0 0;
    }
    &.modular-tile {
      &__main {
        padding: 0 3rem 3rem;
      }
    }
    .form__end-row {
      margin: 3rem 0 0;
    }
    .base-select {
      padding: 0;
    }
    .input-group {
      &:first-child {
        margin: 0;
      }
    }
    .hour-picker {
      .date-time-picker {
        .datetimepicker {
          @media all and (min-width: 416px) and (max-width: map-get($breakpoints, md)) {
            top: 0 !important;
            bottom: 100% !important;
          }
          .datepicker {
            @media all and (min-width: 416px) and (max-width: map-get($breakpoints, md)) {
              top: unset !important;
              bottom: 100% !important;
            }
          }
        }
      }
    }
  }
</style>
