<template>
  <base-main
    default
    data-testid="goals-preview-goal"
    :loading="$options.loading"
  >
    <div
      v-if="goal && goalProfile"
      class="goal-preview"
    >
      <modular-tile
        data-testid="goals-preview-goal-tile"
      >
        <template #default>
          <h2
            class="goal-preview__title"
            data-testid="goals-preview-goal-heading"
          >
              {{ goalTitle }}
          </h2>

          <p
            class="goal-preview__goal-name"
            data-testid="goals-preview-goal-name"
          >
            {{ $t(`${$options.slug}.name`, { goalName }) }}
          </p>
          <h3
            class="goal-preview__goal"
            data-testid="goals-preview-goal-goal"
          >
            {{ goalToAchieve }}
          </h3>

          <div
            v-for="({ question, answers }, i) in questionsAndAnswers"
            :key="i"
            data-testid="goals-preview-goal-question-answers"
          >
            <p
              class="goal-preview__question"
              data-testid="goals-preview-goal-question"
            >
              {{ question }}
            </p>
            <ul
              class="bullet goal-preview__answers-list"
              data-testid="goals-preview-goal-answers"
            >
              <li
                v-for="(answer, j) in answers"
                :key="j"
                data-testid="goals-preview-goal-answer"
              >
                {{ answer }}
              </li>
            </ul>
          </div>

          <base-button
            v-if="goalNotCompleted"
            class="goal-preview__edit-goal"
            data-testid="goals-preview-goal-edit"
            :isDisabled="isGoalCompleting"
            :link="{ name: 'GoalsEditGoal', params: { id } }"
          >
            {{ $t(`${$options.slug}.edit`) }}
          </base-button>

          <base-button
            v-if="showCompleteGoalButton"
            light
            class="goal-preview__complete-goal"
            data-testid="goals-preview-goal-complete"
            :isLoading="isGoalCompleting"
            @click="onCompleteGoal"
          >
            {{ $t(`${$options.slug}.complete`) }}
          </base-button>
        </template>
      </modular-tile>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  import goBack from '@/mixins/goBack'
  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  export default {
    metaInfo() {
      const { goalTitle } = this

      return {
        title: [
          this.$t('views.goals.goals-preview-goal.meta.title'),
          goalTitle,
        ].filter(Boolean).join(' - '),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      ModularTile,
    },

    mixins: [
      goBack,
      weightUnitConverter,
    ],

    props: {
      id: {
        type: Number,
        required: true,
      },
    },

    created() {
      this.fetchStuff()
    },

    destroyed() {
      this.resetBreadcrumbs()
    },

    watch: {
      goal() {
        this.setBreadcrumbTitle(this.goalTitle)
      },
    },

    methods: {
      ...mapActions('goals', [
        'fetchGoal',
        'fetchPlugin',
        'setGoalAsCompleted',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      ...mapMutations('route', [
        'setBreadcrumbTitle',
        'resetBreadcrumbs',
      ]),
      fetchStuff() {
        const promises = [this.fetchGoal(this.id)]

        if (!this.plugin) {
          promises.push(this.fetchPlugin({
            triggerDataId: this.getTriggerDataIdByPluginName('goal'),
            userId: this.getCurrentUserId,
          }))
        }
        Promise.all(promises)
          .catch(this.goBack)
      },
      onCompleteGoal() {
        this.setGoalAsCompleted({ data: { program_id: this.currentProgramId }, id: this.id })
          .then(() => this.addSnackbar({ message: this.$t(`${this.$options.slug}.complete-success`) }))
          .catch(() => this.addSnackbar({ message: this.$t(`${this.$options.slug}.complete-error`) }))
      },
    },

    computed: {
      ...mapGetters('goals', [
        'getProfileById',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
        'isPackageOrProgramOrUserLoading',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'getCurrentUserWeightUnit',
        'isStonesAndPounds',
      ]),
      ...mapGetters('triggers', [
        'getTriggerDataIdByPluginName',
      ]),
      ...mapState('goals', [
        'goal',
        'plugin',
      ]),
      goalProfile() {
        return this.goal && this.plugin
          ? this.getProfileById(this.goal.profile_id)
          : null
      },
      isWeightGoal() {
        return this.goalProfile.template_slug === 'weight_goal'
      },
      isGoalCompleting() {
        return this.getLoadingStatesForActions([
          'goals/setGoalAsCompleted',
        ])
      },
      goalName() {
        return (this.goalProfile?.title || '').toLowerCase()
      },
      goalNotCompleted() {
        return !this.goal.completed_at
      },
      showCompleteGoalButton() {
        return this.goalNotCompleted && this.goalProfile.completed_markable
      },
      goalToAchieve() {
        const answer = this.goal.answers[0].content[0]

        if (this.isWeightGoal) {
          const convert = this.convertToStonesOrPoundsIfNeeded(answer)
          const { abbr } = this.getCurrentUserWeightUnit
          return this.isStonesAndPounds ? `${convert.stones} ${abbr[0]} ${convert.pounds} ${abbr[1]}` : `${convert.value} ${abbr}`
        }

        return answer
      },
      questionsAndAnswers() {
        const goalAnswers = this.goal.answers
          .filter((val, index) => index !== 0)
          .map(({ content }) => content)
        const questions = this.goalProfile.actions.filter((val, index) => index !== 0)

        return goalAnswers.map((answers, index) => ({
          question: questions[index]?.title,
          answers,
        }))
      },
      goalTitle() {
        return this.goalProfile?.title
      },
    },

    loading: [
      'goals/fetchGoal',
      'goals/fetchPlugin',
    ],

    slug: 'views.goals.goals-preview-goal',
  }
</script>

<style lang="scss">
  .goal-preview {
    width: 100%;
    padding: 3rem 0;
    @include min-lg {
      width: 63rem;
      padding: 3rem 0;
    }
    p {
      font-size: 1.2rem;
      font-weight: 600;
      @include min-lg {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
    .modular-tile {
      &__header-row {
        display: none;
      }
      &__content {
        flex-direction: column;
      }
      &__main {
        padding: 0.8rem 2.2rem 2.2rem;
        @include min-lg {
          padding: 0.8rem 3rem 3rem;
        }
      }
    }
    &__title {
      margin-bottom: 2rem;
      font-size: 1.8rem;
      @include min-lg {
        margin-bottom: 2.4rem;
        font-size: 2.2rem;
      }
    }
    &__goal-name {
      margin: 0 0 0.8rem;
      @include min-lg {
        margin: 0 0 1rem;
      }
    }
    &__goal {
      margin-top: 0;
      color: color(primary);
      font-size: 16px;
      @include min-lg {
        margin-top: 0;
        font-size: 1.8rem;
      }
    }
    &__question {
      margin: 0 0 0.5rem;
    }
    &__answers-list {
      margin: 0 0 1rem;
      color: #585858;
    }
    &__complete-goal,
    &__edit-goal {
      align-self: center;
      margin: 1.5rem 0 0;
      @include min-sm {
        align-self: flex-start;
      }
      @include min-lg {
        margin: 2.5rem 0 0;
      }
    }
  }
</style>
