<template>
  <div
    class="plugin-tracking-source-profile"
    data-testid="tracking-source-profile"
  >
    <div class="plugin-tracking-source-profile__section">
      <div
        class="plugin-tracking-source-profile__image"
        :class="{ 'plugin-tracking-source-profile__image--empty': !imageUrl }"
      >
        <img
          v-if="imageUrl"
          alt=""
          data-testid="tracking-source-profile-image"
          :src="imageUrl"
        >
        <inline-svg
          v-else
          role="presentation"
          src="icons/icon-user.svg"
        />
      </div>
    </div>
    <div class="plugin-tracking-source-profile__section">
      <p class="plugin-tracking-source-profile__label">
        {{ $t('Name') }}
      </p>
      <p
        class="plugin-tracking-source-profile__value"
        data-testid="tracking-source-profile-name"
      >
        {{ name }}
      </p>
    </div>
    <div
      v-if="email"
      class="plugin-tracking-source-profile__section"
    >
      <p class="plugin-tracking-source-profile__label">
        {{ $t('Email') }}
      </p>
      <p
        class="plugin-tracking-source-profile__value"
        data-testid="tracking-source-profile-email"
      >
        {{ email }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      email: {
        type: String,
        required: false,
        default: '',
      },
      imageUrl: {
        type: String,
        required: false,
        default: '',
      },
      name: {
        type: String,
        required: false,
        default: 'Unknown',
      },
    },
  }
</script>

<style lang="scss">
  .plugin-tracking-source-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include min-md {
      flex-direction: row;
      text-align: left;
    }
    &__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 1.8rem;
      @include min-md {
        margin: 0 2rem 0 0;
        &:last-child {
          margin: 0;
        }
      }
      &:last-child {
        margin: 0;
      }
    }
    &__image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7rem;
      height: 7rem;
      overflow: hidden;
      border-radius: 50%;
      &--empty {
        border: 1px solid color(primary);
      }
      svg {
        fill: color(dark-grey);
      }
    }
    &__label {
      margin: 0 0 0.2rem;
      color: color(dark-primary);
      font-size: 1.4rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    &__value {
      color: color(dark-grey);
      font-size: 1.4rem;
      font-weight: 600;
      word-break: break-word;
    }
  }
</style>
