<template>
  <base-main
    class="coaching-appointment-view"
    :loading="$options.loading"
    :data-testid="$options.testid"
  >
    <container
      first
      v-if="appointment">
      <simple-tile>
        <div class="coaching-appointment-view__layout">
          <h2
            :data-testid="`${$options.testid}-title`"
            class="coaching-appointment-view__title"
          >
            {{ appointmentWithCoachName }}
          </h2>
          <p
            :data-testid="`${$options.testid}-date`"
            class="coaching-appointment-view__date"
          >
            {{ appointmentDate }}
          </p>
          <div class="coaching-appointment-view__actions">
            <base-button
              no-margin
              v-if="!appointment.cancelled && appointment.result === null"
              class="coaching-appointment-view__edit"
              :data-testid="`${$options.testid}-reschedule-button`"
              :link="{ name: 'CoachingPluginEditAppointment', params: { id } }"
            >
              {{ $t(`${$options.slug}.reschedule`) }}
            </base-button>
            <base-button
              v-if="!appointment.cancelled && appointment.result === null"
              light
              no-margin
              class="coaching-appointment-view__cancel"
              :data-testid="`${$options.testid}-cancel-button`"
              @click="openCancellationDialog"
            >
              {{ $t('Cancel appointment') }}
            </base-button>
          </div>
          <speech-bubble
            class="coaching-apppointment-view__bubble"
            :imageLink="coachImage"
            :testid="$options.testid"
            v-bind="{ text }"
          />
          <h3
            class="coaching-appointment-view__heading"
            :data-testid="`${$options.testid}-focus-heading`"
          >
           {{ $t(`${$options.slug}.heading-1`) }}
          </h3>
          <ul
            class="coaching-appointment-view__focus bullet"
            :data-testid="`${$options.testid}-focus-list`"
          >
            <li
              v-for="(message, i) in messageParagraphs"
              :key="i"
              class="coaching-appointment-view__message"
            >
              {{ message }}
            </li>
          </ul>
          <h3
            class="coaching-appointment-view__heading"
            :data-testid="`${$options.testid}-notes-heading`"
          >
            {{ $t(`${$options.slug}.heading-2`) }}
          </h3>
          <div
            v-if="appointment.notes"
            class="coaching-appointment-view__notes"
            :data-testid="`${$options.testid}-notes-text`"
            v-html="appointment.notes"
          />
          <p
            v-else
            class="coaching-appointment-view__notes"
            :data-testid="`${$options.testid}-notes-empty`"
          >
            {{ $t(`${$options.slug}.no-notes`) }}
          </p>
        </div>
      </simple-tile>
    </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'
  import SimpleTile from '@/components/tiles/simple-tile'

  import { DATE_FORMATS } from '@/constants'

  import eventBus from '@/tools/event-bus'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.coaching-plugin.coaching-plugin-show-appointment.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      SimpleTile,
      SpeechBubble,
    },

    props: {
      fromNewEdit: {
        type: Boolean,
        required: false,
        default: false,
      },
      id: {
        type: [Number, String],
        required: true,
      },
    },

    created() {
      this.fetchAppointmentAndCoach()
      eventBus.$on('cancellationConfirmation', this.cancel)
    },

    beforeDestroy() {
      this.closeDialog()
      eventBus.$off('cancellationConfirmation', this.cancel)
    },

    watch: {
      id: 'fetchAppointmentAndCoach',
      isReady: {
        handler() {
          if (!this.isReady) return

          this.$mam.event('Coaching - Appointment viewed', {
            appointment_id: this.appointment.id,
            coach_id: this.coach.user.ppuid,
            coach_name: this.coach.name,
            appointment_status: this.appointment.time_status,
          })
        },
        immediate: true,
      },
    },

    methods: {
      ...mapActions('coaching', [
        'cancelAppointment',
        'fetchAppointment',
        'fetchCoach',
      ]),
      ...mapActions('dialog', [
        'closeDialog',
        'openDialog',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      fetchAppointmentAndCoach() {
        if (!this.coach) {
          this.fetchCoach({ programId: this.currentProgramId, userId: this.getCurrentUserId })
            .catch(() => this.$router.replace({ name: 'CoachingPlugin' }))
        }

        if (this.fromNewEdit) return

        this.fetchAppointment(this.id)
          .catch(() => this.$router.replace({ name: 'CoachingPlugin' }))
      },
      openCancellationDialog() {
        const [time] = this.time.split(' ')

        this.openDialog({
          component: 'CoachingAppointmentCancellation',
          props: { date: this.appointmentDate, eventBus, time },
        })
      },
      cancel() {
        this.cancelAppointment(this.id)
          .then(() => {
            this.addSnackbar({ message: this.$t(`${this.$options.slug}.cancelled-appointment`) })
            this.$router.push({ name: 'CoachingPlugin' })
          })
      },
      getTimedata(appointment) {
        return moment(appointment.timedata.call.time, 'YYYY-MM-DD HH:mm:ss')
            .add(appointment.timedata.call.duration, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss')
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
        'getTimeRange',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'currentProgramId',
      ]),
      ...mapGetters('user', [
        'getCurrentUserId',
        'getCurrentUserFirstName',
      ]),
      ...mapState('coaching', [
        'appointment',
        'coach',
      ]),
      isReady() {
        return this.coach && this.appointment && this.id === this.appointment?.id
      },
      text() {
        if (!this.appointment) return

        const name = this.getCurrentUserFirstName
        const firstLine = this.$t(`${this.$options.slug}.next-call`, {
          name: name || '',
        })

        return this.appointment.note
          ? firstLine
          : `${firstLine}\n
            ${this.$t(`${this.$options.slug}.notes-appear`)}`
      },
      time() {
        if (!this.appointment) return

        return this.getTimeRange(this.appointment.timedata.call.time,
        this.getTimedata(this.appointment))
      },
      isPast() {
        if (!this.appointment) return

        return moment(this.appointment.since).isBefore()
      },
      messageParagraphs() {
        return this.appointment?.message
          ? this.appointment.message
            .split('\n')
            .filter(paragraph => paragraph.length)
          : []
      },
      appointmentDate() {
        const { appointment } = this

        if (!appointment) return ''

        const callTime = moment(appointment.timedata.call.time)
        const from = callTime
          .format(DATE_FORMATS.dateTime)
        const to = moment(callTime)
          .add(appointment.timedata.call.duration, 'minutes')
          .format(DATE_FORMATS.time)

        return `${from} - ${to}`
      },
      appointmentWithCoachName() {
        return this.$t(`${this.$options.slug}.appointment-with`, {
          name: this.coach?.name,
        })
      },
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin-show-appointment',
    testid: 'coaching-plugin-show-appointment',

    loading: [
      'coaching/fetchAppointment',
      'coaching/fetchCoach',
    ],
  }
</script>

<style lang="scss">
  .coaching-appointment-view {
    &__layout {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &__title {
      margin: 0;
    }
    &__date {
      margin: 0.5rem 0 0;
      color: color(primary);
      font-size: 1.8rem;
      font-weight: 600;
    }
    &__actions {
      display: grid;
      grid-row-gap: 2rem;
      margin: 2rem 0 0;
      @include min-md {
        display: flex;
        .button:not(:last-child) {
          margin: 0 2rem 0 0;
        }
      }
    }
    &__heading {
      margin: 4rem 0 0;
      font-size: 1.4rem;
      font-weight: 600;
    }
    &__focus {
      margin: 1rem 0 0;
      font-size: 1.4rem;
    }
    &__notes {
      margin: 1rem 0 0;
      font-size: 1.4rem;
    }
    .speech-bubble {
      margin: 4rem 0 0;
      white-space: pre-line;
    }
    .simple-tile__main {
      padding: 3rem;
    }
  }
</style>
