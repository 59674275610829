<template>
  <base-main
    class="coaching-match"
    data-testid="coaching-plugin-start-match"
  >
    <container stepper>
      <simple-tile>
        <div class="coaching-match__layout">
          <h2
            class="coaching-match__heading"
            data-testid="coaching-plugin-start-match-heading"
          >
            {{ $t(`${$options.slug}.heading`) }}
          </h2>
          <speech-bubble :imageLink="coachImage">
            {{ $t("Hello") }} {{ getCurrentUserFirstName }}!
            You can book your first appointment with me by following the
            'Book appointment' link below.
          </speech-bubble>
          <h3
            class="coaching-match__name"
            data-testid="coaching-plugin-start-match-coach-name"
          >
             {{ $t(`${$options.slug}.coach`) }} {{ coach.name }}
          </h3>
          <p
            class="coaching-match__title"
            data-testid="coaching-plugin-start-match-coach-title"
          >
            {{ coach.title }}
          </p>
          <p
            v-for="(descriptionParagraph, i) in coachDescription"
            :key="i"
            data-testid="coaching-plugin-start-match-coach-description"
            class="coaching-match__description"
          >
            {{ descriptionParagraph }}
          </p>
          <div class="coaching-match__actions">
            <base-button
              no-margin
              data-testid="'coaching-plugin-start-match-finish-button"
              :link="{ name: 'CoachingPlugin' }"
            >
              {{ $t("Finish") }}
            </base-button>
            <base-button
              no-margin
              data-testid="coaching-plugin-start-match-book-appointment-button"
              :link="{ name: 'CoachingPluginNewAppointment' }"
            >
              {{ $t("Book appointment") }}
            </base-button>
          </div>
        </div>
      </simple-tile>
    </container>
  </base-main>
</template>

<script>
  import { mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'
  import SimpleTile from '@/components/tiles/simple-tile'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.coaching-plugin.coaching-plugin-start-match.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      SimpleTile,
      SpeechBubble,
    },

    props: {
      coach: {
        type: Object,
        required: true,
      },
      fromStart: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      ...mapGetters('coaching', [
        'coachImage',
      ]),
      ...mapGetters('user', [
        'getCurrentUserFirstName',
      ]),
      coachDescription() {
        return this.coach.description
          ? this.coach.description.split('\n\n')
          : ''
      },
    },

    beforeRouteEnter(to, from, next) {
      to.params.fromStart
        ? next()
        : next({ name: 'CoachingPlugin' })
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin-start-match',
  }
</script>

<style lang="scss">
  .coaching-match {
    max-width: 77rem;
    &__heading {
      margin: 0;
      font-size: 2.2rem;
      font-weight: 800;
    }
    &__name {
      margin: 3rem 0 0;
      font-size: 2.2rem;
      font-weight: 800;
    }
    &__title {
      margin: 1rem 0 0;
      color: color(primary);
      font-size: 1.8rem;
      font-weight: 600;
    }
    &__description {
      margin: 2rem 0 0;
    }
    &__actions {
      display: grid;
      grid-row-gap: 2rem;
      margin: 2rem 0 0;
      @include min-md {
        display: flex;
        margin: 2rem 0 0;
        .button:not(:last-child) {
          margin: 0 1rem 0 0;
        }
      }
    }
    .speech-bubble {
      margin: 3rem 0 0;
    }
    .simple-tile {
      padding: 3rem;
    }
  }
</style>
