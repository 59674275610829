<template>
  <div
    class="legal-documents-list"
    :data-testid="dataTestid"
  >
    <h2
      class="legal-documents-list__header"
      :data-testid="`${dataTestid}-heading`"
    >
      {{ $t(`${$options.slug}.header`) }}
    </h2>
    <base-table>
      <base-table-header
        :data-testid="`${dataTestid}-table-header`"
        :items="$options.headings"
      />
      <base-table-row
        v-for="(item, i) in acceptedLegalDocuments"
        :key="`${item.id}-${i}`"
        class="legal-documents-list__item"
        :data-testid="`${dataTestid}-document`"
      >
          <base-table-element>
            <button
              class="legal-documents-list__link"
              :data-testid="`${dataTestid}-show-document`"
              @click="showLegalDocument(item)"
            >
              {{ item.display_title }}
            </button>
          </base-table-element>
          <base-table-element
            :data-testid="`${dataTestid}-version`"
          >
            {{ item.version }}
          </base-table-element>
          <base-table-element
            :data-testid="`${dataTestid}-accepted-at`"
          >
            {{ formatAcceptedDate(item) }}
          </base-table-element>
      </base-table-row>
    </base-table>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'

  import BaseTable from '@/components/base/table/BaseTable'
  import BaseTableElement from '@/components/base/table/element/BaseTableElement'
  import BaseTableHeader from '@/components/base/table/header/BaseTableHeader'
  import BaseTableRow from '@/components/base/table/row/BaseTableRow'

  import { DATE_FORMATS } from '@/constants'

  import matchMedia from '@/mixins/matchMedia'
  import testid from '@/mixins/testid/testid'

  const slug = 'component.legal-document.legal-document-list'

  export default {
    components: {
      BaseTable,
      BaseTableElement,
      BaseTableHeader,
      BaseTableRow,
    },

    mixins: [
      matchMedia({
        media: 'min-md',
      }),
      testid('legal-documents-list'),
    ],

    props: {
      acceptedLegalDocuments: {
        type: Array,
        required: true,
      },
    },

    methods: {
      ...mapActions('dialog', [
        'openDialog',
      ]),
      showLegalDocument(legalDocument) {
        this.openDialog({
          component: 'LegalDocument',
          props: { legalDocument },
        })
      },
      formatAcceptedDate({ accepted_at: acceptedAt }) {
        return moment
          .utc(acceptedAt)
          .format(this.isMinMd
            ? DATE_FORMATS.dateTime
            : DATE_FORMATS.dateTimeShort)
      },
    },

    slug,

    headings: [
      `${slug}.heading-1`,
      `${slug}.heading-2`,
      `${slug}.heading-3`,
    ],
  }
</script>

<style lang="scss">
  .legal-documents-list {
    &__header {
      text-align: center;
      font-weight: 600;
    }
    &__link {
      padding: 0;
      font-weight: 600;
      text-decoration: underline;
      max-width: 20rem;
      text-align: left;
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
