<template functional>
  <div
    class="stepper-icons"
    :class="{
      'stepper-icons--darker': (props.isCurrent || props.isPrevious) && !props.hasErrors,
      'stepper-icons--error': props.hasErrors,
    }"
    data-testid="stepper-old-icons"
  >
    <div
      v-if="(props.isCurrent || props.isNext) && !props.hasErrors"
      class="stepper-icon">
        {{ props.step }}
    </div>
    <inline-svg
      v-else-if="props.isPrevious && !props.hasErrors"
      class="stepper-icon"
      aria-label="Step completed"
      src="tick.svg"/>
    <div
      v-else-if="props.hasErrors"
      class="stepper-icon">
        !
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      isCurrent: {
        type: Boolean,
        required: true,
      },
      isNext: {
        type: Boolean,
        required: true,
      },
      isPrevious: {
        type: Boolean,
        required: true,
      },
      hasErrors: {
        type: Boolean,
        required: true,
      },
      step: {
        type: Number,
        required: true,
      },
    },
  }
</script>

<style lang="scss">
  .stepper {
    &-icons {
      position: relative;
      flex-shrink: 0;
      height: 3rem;
      width: 3rem;
      background-color: color(grey);
      border-radius: 50%;
      z-index: 2;
      transition: background-color 0.25s ease;
      @media all and (min-width: map-get($breakpoints, sm)) {
        height: 3.5rem;
        width: 3.5rem;
      }
      &--darker {
        background-color: color(primary);
      }
      &--error {
        background-color: color(stepper-error);
      }
      &:before {
        content: '';
        position: absolute;
        bottom: -0.8rem;
        left: 0;
        height: 0.8rem;
        width: 100%;
        background-color: color(off-white);
      }
    }
    &-icon {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      color: color(_white);
      font-weight: 600;
      @media all and (min-width: map-get($breakpoints, sm)) {
        font-size: 1.5rem;
      }
      svg {
        height: 2.3rem;
        width: 1.7rem;
        fill: color(_white);
      }
    }
  }
</style>
