<template>
  <div
    class="tracking-goals"
    :data-testid="dataTestid"
  >
    <div
      v-for="(goal, i) in goals"
      :key="i"
      class="tracking-goals__item"
    >
      <modular-tile
        :headerText="goal.title"
        :imagePosition="'top'"
        :image="imageUrl"
        :data-testid="dataTestid"
      >
        <p v-if="goal.value">
          {{ goal.value }}
        </p>
        <template #action>
          <base-button
            light
            no-margin
            :data-testid="`${dataTestid}-edit-button`"
            :link="goal.link"
          >
            {{ goalButtonText(goal) }}
          </base-button>
        </template>
      </modular-tile>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import ModularTile from '@/components/tiles/modular-tile'

  import { GOAL_CATEGORIES } from '@/constants'

  import testid from '@/mixins/testid/testid'
  import weightUnitConverter from '@/mixins/weight-unit-converter/weightUnitConverter'

  export default {
    components: {
      BaseButton,
      ModularTile,
    },

    mixins: [
      testid('tracking-goals'),
      weightUnitConverter,
    ],

    props: {
      addButtonText: {
        type: String,
        required: false,
        default: 'Add goal',
      },
      editButtonText: {
        type: String,
        required: false,
        default: 'Edit goal',
      },
      goalTypeId: {
        type: Number,
        required: true,
      },
      imageUrl: {
        type: String,
        required: false,
        default: '',
      },
    },

    methods: {
      goalButtonText({ link: { params } }) {
        const isProfileGoal = !!params.profileId

        return isProfileGoal
          ? this.addButtonText
          : this.editButtonText
      },

      getValue(goal) {
        if (!goal) return null

        if (this.isWeightTracking) {
          const convertedWeightObj = this.convertToStonesOrPoundsIfNeeded(
            goal?.answers?.[0].content[0],
          )
          if (this.isStonesAndPounds) {
            return `${convertedWeightObj.stones} st ${convertedWeightObj.pounds} lbs`
          }
          return `${convertedWeightObj.value} ${this.getCurrentUserWeightUnit.abbr}`
        }

        return goal?.answers?.[0].content[0]
      },
    },

    computed: {
      ...mapGetters('goals', [
        'getProfilesByGoalTypeId',
        'getGoalByProfileId',
      ]),
      ...mapGetters('user', [
        'isStonesAndPounds',
        'getCurrentUserWeightUnit',
      ]),
      isWeightTracking() {
        return GOAL_CATEGORIES.weightTracking == this.goalTypeId
      },
      goals() {
        return this.getProfilesByGoalTypeId(this.goalTypeId)
          .map(item => {
            const goal = this.getGoalByProfileId(item.id)
            const isGoalProfile = !goal
            const { id } = isGoalProfile ? item : goal
            const link = isGoalProfile
              ? { name: 'GoalsAddGoal', params: { profileId: id } }
              : { name: 'GoalsEditGoal', params: { id } }

            return {
              link,
              title: item.title,
              value: this.getValue(goal),
            }
        })
      },
    },
  }
</script>

<style lang="scss">
  .tracking-goals {
    @include min-lg {
      width: 27rem;
      margin: 0 3rem 0 0;
    }
    &__item {
      margin: 0 0 2rem;
      .modular-tile {
        &__main {
          @include max-lg {
            padding: 2rem;
          }
        }
        &__header-row {
          @include max-lg {
            align-items: flex-start;
            min-height: 3rem;
          }
        }
        &__top-image-row {
          @include max-lg {
            display: none;
          }
        }
      }
      .button {
        margin: 1rem 0 0;
        @include min-lg {
          margin: 0;
          padding: 2px 1.8rem 0;
        }
      }
    }
  }
</style>
