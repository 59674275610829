<template>
  <base-main
    class="step-tracking-log"
    :loading="$options.loading">
      <container
        first
        narrow>

        <stepper-custom-form
          ref="form"
          data-testid="weight-tracking-log-form"
          :buttonLabels="{
            finishLabel: showWarning ? 'Yes, continue' : i18n('log-steps'),
            previousLabel: showWarning ? 'No, cancel' : 'Previous'
          }"
          :isLoading="isSaving"
          @cancel="goBack"
          @submit="onSubmit"
          @previous="previous"
        >
          <template #default>
            <stepper-custom-step
              v-for="(formField, i) in [dateField, valueField]"
              :key="i"
              data-testid="weight-tracking-log-stepper-custom-step"
              :title="titleFormField(formField)"
              :description="i === 0 && isEdit ? 'You are editing a log for the date below' : ''"
            >
            <div
              v-if="formField && !showWarning"
              class="log-weight-tracking__container"
              data-testid="log-weight-tracking-content"
              >
                <form-fields-generator-field
                  :dataModel="form"
                  v-bind="{ field: formField }"
                />
              </div>
            <div v-if="showWarning">
              <h2>
                This will replace all weight data previously recorded for this date.
              </h2>
              <h2>Do you wish to continue?</h2>
            </div>
            </stepper-custom-step>
          </template>
        </stepper-custom-form>
      </container>
  </base-main>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'

  import FormFieldsGeneratorField from '@/components/forms/form-fields-generator/FormFieldsGeneratorField'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import { DATE_FORMATS } from '@/constants'
  import goBack from '@/mixins/goBack'
  import i18n from '@/mixins/i18n'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.step-tracking.step-tracking-log.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      StepperCustomForm,
      StepperCustomStep,
      FormFieldsGeneratorField,
    },
    mixins: [
      i18n,
      goBack,
    ],

    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
    },

    mounted() {
      if (!this.id) return
      this.isEdit = true

      this.fetchDayStep(this.id).then(item => {
        this.itemById = item
        this.fillForm()
      })
    },

    created() {
      this.form.date = moment.utc().utcOffset(this.getTimezoneOffset).format(DATE_FORMATS.dateShort)

      if (this.settings) return

      this.fetchSettings()
        .then(() => {
          if (!this.settings) {
            return this.$router.push({ name: 'StepTrackingSettings' })
          }

        if (this.isAutomatically) this.$router.push({ name: 'StepTracking' })
      })
    },

    data() {
      return {
        form: {
          activity: '',
          logging_steps: '',
          date: '',
        },
        showWarning: false,
        itemById: null,
        isEdit: false,
      }
    },

    methods: {
      ...mapActions('stepTracking', [
        'fetchSettings',
        'logSteps',
        'fetchDayStep',
      ]),
      ...mapMutations('snackbars', [
        'addSnackbar',
      ]),
      async onSubmit(isValid) {
        if (!this.showWarning && this.form.date !== this.id) {
          const { label, value } = await this.fetchDayStep(this.form.date)
          if (label && value) {
            this.showWarning = true
            return
          }
        }
        if (!isValid) return

        const formData = new FormData()

        Object.entries(this.form).forEach(([key, value]) => {
          formData.append(key, value)
        })

        this.logSteps(formData)
          .then(() => {
            this.addSnackbar({ message: 'Step Tracking log saved' })
            this.$router.push({ name: 'StepTracking' })
          })
      },
      titleFormField(formField) {
        return formField?.props?.legend || formField?.props?.label || ''
      },
      previous() {
        if (this.showWarning) {
          this.goBack()
        }
      },
      fillForm() {
        const [value] = this.itemById.value
        this.form.logging_steps = value
        this.form.date = this.id
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('stepTracking', [
        'settings',
      ]),
      ...mapGetters('user', [
        'getTimezoneOffset',
      ]),
      isAutomatically() {
        return this.settings.google_code
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'stepTracking/logSteps',
        ])
      },
      today() {
        return moment().format(DATE_FORMATS.date)
      },
      dateField() {
        /* eslint-disable camelcase */
        return {
          type: 'CalendarPickerGroup',
          class: 'log-glucose__form-field',
          name: 'date',
          props: {
            format: DATE_FORMATS.dateShort,
            formatted: DATE_FORMATS.date,
            hasHiddenAsterisk: true,
            label: 'Select the date of your tracking',
            maxDate: moment
              .utc()
              .utcOffset(this.getTimezoneOffset)
              .format('YYYY-MM-DD 23:59'),
            minDate: moment
              .utc(this.settings?.created_at, 'YYYY-MM-DD HH:mm:ss')
              .utcOffset(this.getTimezoneOffset)
              .format('YYYY-MM-DD 00:00'),
            testid: this.dataTestid,
            outputFormat: DATE_FORMATS.dateShort,
            validation: { required: true },
            required: true,
            disabled: this.isEdit,
          },
        }
        /* eslint-enable camelcase */
      },
      valueField() {
        return {
          type: 'InputGroup',
          name: 'logging_steps',
          class: 'step-tracking-log',
          props: {
            hasHiddenAsterisk: true,
            label: this.i18n('daily-steps'),
            legend: this.i18n('log-your-steps'),
            placeholder: this.i18n('daily-steps'),
            max: 50000,
            min: 0,
            step: 0.01,
            type: 'number',
            validation: this.$options.validation.logging_steps,
          },
        }
      },
    },

    slug: 'component.dialog.log-steps-dialog',

    validation: {
      logging_steps: {
        required: true,
        numeric: true,
        min_value: 0,
        max_value: 50000,
      },
    },

    loading: [
      'stepTracking/fetchSettings',
    ],
  }
</script>

<style lang="scss">
  .step-warning {
    display: flex;
    justify-content: center;
  }
  .step-tracking-log {
    &-adding {
      color: color(secondary-text);
      margin: 0 0 1rem 0;
    }
    &-today {
      color: color(dark-primary);
      font-size: 1.7rem;
      font-weight: 600;
    }
    &-form {
      display: flex;
      flex-direction: column;
    }
    &-error {
      margin: 0 0 0 0.4rem;
    }
    .fieldset-group-legend {
      color: color(dark-primary);
      font-weight: 600;
    }
  }
</style>
