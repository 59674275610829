<template>
  <base-main
    class="tracking-delete">
      <container
        first
        narrow>

        <stepper-custom-form
          ref="form"
          data-testid="tracking-delete-stepper"
          :buttonLabels="{
            finishLabel: 'Yes, continue',
            previousLabel: 'No, cancel'
          }"
          @cancel="$emit('cancel')"
          @submit="$emit('onDelete')"
        >
          <!-- :isLoading="isSaving" -->
          <template #default>
            <stepper-custom-step
              :key="1"
              data-testid="tracking-delete-step"
            >
              <!-- :title="titleFormField(formField)" -->

              <h2>
                This will remove the selected entry and all
                information it contains from this tracking tool.
              </h2>
              <h2>Do you wish to continue?</h2>
            </stepper-custom-step>
          </template>
        </stepper-custom-form>
      </container>
  </base-main>
</template>

<script>
  import {
    BaseMain,
  } from '@/components/base'
  import Container from '@/components/container/Container'
  import StepperCustomForm from '@/components/stepper-custom/StepperCustomForm'
  import StepperCustomStep from '@/components/stepper-custom/StepperCustomStep'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      StepperCustomForm,
      StepperCustomStep,
      BaseMain,
      Container,
    },

    mixins: [
      testid(),
    ],

    props: {
      headings: {
        type: Array,
        default: () => [],
      },
      items: {
        type: Array,
        default: () => [],
      },
      name: {
        type: String,
        default: '',
      },
      hasEditButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      isEditEmit: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  }
</script>

<style lang="scss">
</style>
