<template>
  <base-main class="coaching-no-match">
    <container first>
      <simple-tile>
        <div class="coaching-no-match__main">
          <h2 class="coaching-no-match__heading">
            {{ $t(`${$options.slug}.heading`) }}
          </h2>
          <speech-bubble :image="'support.png'">
            {{ $t(`${$options.slug}.coach-text`) }}
          </speech-bubble>
          <div class="coaching-no-match__actions">
            <base-button
              no-margin
              :link="{ name: 'CoachingPluginStart', params: { changeTimes: true } }"
            >
              {{ $t(`${$options.slug}.change-times`) }}
            </base-button>
            <base-button
              no-margin
              :isDisabled="isContacting"
              :isLoading="isContacting"
              :link="{ name: 'Support' }"
            >
              {{ $t(`${$options.slug}.contact-support`) }}
            </base-button>
          </div>
        </div>
      </simple-tile>
    </container>
  </base-main>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'

  import BaseButton from '@/components/base/BaseButton'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import SpeechBubble from '@/components/speech-bubble/SpeechBubble'
  import SimpleTile from '@/components/tiles/simple-tile'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.plugins.coaching-plugin.coaching-plugin-start-no-match.meta.title'),
      }
    },

    components: {
      BaseButton,
      BaseMain,
      Container,
      SimpleTile,
      SpeechBubble,
    },

    props: {
      fromStart: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    methods: {
      ...mapActions('coaching', [
        'contactSupport',
      ]),
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isContacting() {
        return this.getLoadingStatesForActions([
          'coaching/contactSupport',
        ])
      },
    },

    beforeRouteEnter(to, from, next) {
      to.params.fromStart
        ? next()
        : next({ name: 'CoachingPlugin' })
    },

    slug: 'views.plugins.coaching-plugin.coaching-plugin-start-no-match',
  }
</script>

<style lang="scss">
  .coaching-no-match {
    &__heading {
      margin: 0 0 3rem;
      font-size: 2.2rem;
      font-weight: 800;
    }
    &__actions {
      display: grid;
      grid-row-gap: 2rem;
      margin: 3.6rem 0 0;
      @include min-md {
        display: flex;
        .button:not(:last-child) {
          margin: 0 1rem 0 0;
        }
      }
    }
    .simple-tile {
      max-width: 63rem;
      margin: 0 auto;
    }
    .simple-tile__main {
      padding: 3rem;
    }
  }
</style>
