<template>
  <base-main
    appbar
    class="reset-password"
    :loading="$options.loading">
      <container
        v-if="isTokenValid"
        first
        white>
          <h2>Reset Password</h2>

          <base-form
            class="reset-password-form"
            autocomplete="off"
            v-bind="{ isSaving }"
            @submit="onSubmit">

              <input-group
                has-hidden-asterisk
                :label="'New password'"
                :name="'password'"
                :type="'password'"
                :validation="$options.validation.password"
                v-model="form.password"/>

              <input-details
                :inputError="customErrorMessage"/>

              <template #button>
                Reset password
              </template>
          </base-form>

          <snackbar
            :isOpen.sync="isSnackbarOpen">
              <template slot="content">
                Something went wrong. Please try again.
              </template>
              <template slot="action">
                <!-- eslint-disable-next-line owd-ch/require-testid -->
                <base-button
                  :type="'submit'"
                  @click="onSubmit">
                    Retry
                </base-button>
              </template>
          </snackbar>

      </container>

      <empty-view
        v-else-if="!isLoading">
          This reset password URL is invalid,<br>
          please contact us for assistance
          at <a href="mailto:customersupport@changinghealth.com">
          customersupport@changinghealth.com</a>
      </empty-view>

  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseForm from '@/components/base/BaseForm'
  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import EmptyView from '@/components/empty-view/EmptyView'
  import InputDetails from '@/components/forms/input-details/InputDetails'
  import InputGroup from '@/components/forms/input-group/InputGroup'
  import Snackbar from '@/components/snackbar/Snackbar'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.auth.auth-reset-password.meta.title'),
      }
    },

    components: {
      BaseForm,
      BaseMain,
      Container,
      EmptyView,
      InputDetails,
      InputGroup,
      Snackbar,
    },

    props: {
      token: {
        type: String,
        required: true,
      },
    },

    created() {
      this.verifyResetPasswordToken(this.token)
        .then(() => {
          this.isTokenValid = true
        })
        .catch(() => {
          this.isTokenValid = false
        })
    },

    data() {
      return {
        customErrorMessage: '',
        form: {
          password: '',
        },
        isSnackbarOpen: false,
        isTokenValid: false,
      }
    },

    methods: {
      ...mapActions('auth', [
        'logout',
      ]),
      ...mapActions('user', [
        'resetPassword',
        'verifyResetPasswordToken',
      ]),
      onSubmit(isValid) {
        if (!isValid) return

        this.closeSnackbar()

        this.resetPassword({
          password: this.form.password,
          code: this.token,
        })
        .then(this.logoutIfNecessary)
        .then(this.$nextTick)
        .then(() => this.$router.replace({ name: 'AuthLogin' }))
        .catch(err => this.handleError(err))
      },
      logoutIfNecessary() {
        return new Promise(resolve => {
          this.isCurrentUserAuthorized
            ? this.logout()
                .then(resolve)
            : resolve()
        })
      },
      handleError(err) {
        err?.message
          ? this.customErrorMessage = err.message
          : this.isSnackbarOpen = true
      },
      closeSnackbar() {
        this.isSnackbarOpen = false
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('auth', [
        'isCurrentUserAuthorized',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'user/resetPassword',
        ])
      },
    },

    validation: {
      password: {
        required: true,
        min: 9,
        max: 32,
      },
    },

    loading: [
      'user/verifyResetPasswordToken',
    ],
  }
</script>
