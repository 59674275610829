<template>
  <div
    class="article-profile-data"
    :class="{ 'article-profile-data--can-proceed': canProceed }"
    data-testid="base-article-profile-data"
  >
    <optional-profile-data-journey
      ref="optionalProfileDataJourney"
      data-testid="base-article-profile-data-content"
      :hasSubmitButton="false"
      v-bind="{ data, isSaving, item }"
      v-slot="{ onSubmit }"
    >
      <journey-navigation
        v-if="isJourney"
        :testid="'base-article-profile-data'"
        :articleId="$route.params.id"
        :beforeNavigation="onSubmit"
        :isLoading="isNavigationLoading"
        v-bind="{ canProceed }"
      />
    </optional-profile-data-journey>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { JourneyNavigation } from '@/components/journey'
  import { OptionalProfileDataJourney } from '@/components/optional-profile-data'

  export default {
    inheritAttrs: false,

    components: {
      JourneyNavigation,
      OptionalProfileDataJourney,
    },

    props: {
      id: {
        type: [String, Number],
        required: true,
      },
      isJourney: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('program', [
        'getArticle',
      ]),
      ...mapGetters('optionalProfileData', [
        'getDataById',
      ]),
      isLoading() {
        return this.getLoadingStatesForActions([
          'article/fetchArticle',
          'program/fetchNextSection',
        ])
      },
      isSaving() {
        return this.getLoadingStatesForActions([
          'articles/changeProgress',
          'optionalProfileData/updateOptionalData',
          'program/fetchNextSection',
        ])
      },
      data() {
        if (!this.item) return

        return this.getDataById(this.item.category, this.item.subject_id)
      },
      canProceed() {
        return !this.isSaving && !this.isLoading
      },
      item() {
        return this.getArticle(this.id)
      },
      isNavigationLoading() {
        return this.isLoading || this.isSaving
      },
    },
  }
</script>

<style lang="scss">
  $block: 'article-profile-data';

  .#{$block} {
    @include max-lg {
      max-height: 100%;
      overflow-x: hidden;
    }
    &__heading {
      font-size: 2.2rem;
      font-weight: 800;
    }
    &__information {
      margin: 0 0 2rem;
      color: color(primary);
      font-size: 1.8rem;
      font-weight: 600;
    }
    &:not(.#{$block}--can-proceed) {
      .article-navigation-button {
        &:last-child {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
    .modular-tile {
      max-width: 63rem;
      &__main {
        padding: 0 3rem 3rem;
      }
      &__content {
        flex-direction: column;
      }
    }
    .form__end-row {
      margin: 3rem 0 2rem;
    }
  }
</style>
