<template>
  <tracking-delete-stepper
    @cancel="cancel"
    @onDelete="onDelete"/>
</template>
<script>
  import { mapActions } from 'vuex'

  import TrackingDeleteStepper from '@/components/tracking/TrackingDeleteStepper'

  export default {
    components: {
      TrackingDeleteStepper,
    },

    props: {
      id: {
        type: String,
        required: false,
        default: '',
      },
    },

    methods: {
      ...mapActions('glucoseTracking', [
        'deleteReading',
      ]),
      cancel() {
      this.$router.push({ name: 'GlucoseTracking' })
      },
      async onDelete() {
        try {
          await this.deleteReading(this.id)
        this.$router.push({ name: 'GlucoseTracking' })
        } catch (err) {
        //
        }
      },
    },
  }

</script>
