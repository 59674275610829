<template>
  <base-main
    class="profile"
    data-testid="profile"
  >
    <container
      no-padding
    >
      <modular-tile
        class="profile__tile"
        :headerText="headerText"
      >
        <div
          class="profile__content"
          data-testid="profile-content"
        >
          <profile-form
            :method="'PATCH'"
            :showSnackbar.sync="showSnackbar"
            v-bind="{ error, hasEmailChanged, isSaving, isSuccess, optionalData, user }"
            @submit="onProfileFormSubmit"
          />
          <!-- Hiding legacy legal documents on Profile -->
          <legal-document-list
            v-if="false"
            class="profile__legal-documents"
            v-bind="{ acceptedLegalDocuments }"
          />
        </div>
      </modular-tile>
    </container>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'

  import BaseMain from '@/components/base/BaseMain'
  import Container from '@/components/container/Container'
  import ProfileForm from '@/components/forms/profile-form/ProfileForm'
  import LegalDocumentList from '@/components/legal-document/LegalDocumentList'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  export default {
    metaInfo() {
      return {
        title: this.$t('views.profile.meta.title'),
      }
    },

    components: {
      BaseMain,
      Container,
      ModularTile,
      ProfileForm,
      LegalDocumentList,
    },

    mounted() {
      this.fetchAllLegalDocuments()
    },

    data() {
      return {
        isSuccess: false,
        showSnackbar: false,
        error: null,
        hasEmailChanged: false,
      }
    },

    methods: {
      ...mapActions('optionalProfileData', [
        'updateOptionalData',
      ]),
      ...mapActions('user', [
        'updateUser',
      ]),
      ...mapActions('legalDocument', [
        'fetchAllLegalDocuments',
      ]),
      onProfileFormSubmit({ form, formOptionalData }) {
        Promise.all([
          this.updateUser({ userId: this.user.id, form }),
          this.shouldUpdateOptionalData(formOptionalData),
        ])
          .then(() => {
            this.isSuccess = true
            this.showSnackbar = true
            this.error = null
            this.hasEmailChanged = form.get('email') == this.user.new_email
          })
          .then(this.fetchAllLegalDocuments)
          .catch(err => {
            this.isSuccess = false
            this.showSnackbar = true
            this.error = err
            this.hasEmailChanged = false
          })
          .finally(() => {
            this.showSnackbar = false
          })
      },
      shouldUpdateOptionalData(form) {
        return form
                ? this.updateOptionalData(form)
                : Promise.resolve()
      },
    },

    computed: {
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapGetters('user', [
        'getCurrentUserFirstName',
      ]),
      ...mapState('optionalProfileData', [
        'optionalData',
      ]),
      ...mapState('user', [
        'user',
      ]),
      ...mapState('legalDocument', [
        'acceptedLegalDocuments',
      ]),
      isSaving() {
        return this.getLoadingStatesForActions([
          'optionalProfileData/updateOptionalData',
          'user/updateUser',
        ])
      },
      headerText() {
        return this.$t(`${this.$options.slug}.header-text`, { name: this.getCurrentUserFirstName })
      },
    },

    slug: 'views.profile',
  }
</script>

<style lang="scss">
  $modularTileBlock: modular-tile;

  .profile {
    max-width: none;
    @include max-md {
      padding: 0;
    }
    &__tile {
      flex-grow: 0;
      margin: 3.4rem auto;
      @include max-sm {
        border-radius: 0;
      }
      @include min-sm {
        max-width: 63rem;
      }
      .#{$modularTileBlock}__header-row {
        margin: 2rem 0 1rem;
      }
      .#{$modularTileBlock}__header {
        font-size: 2.2rem;
        font-weight: 800;
        text-align: center;
        text-transform: none;
      }
      .#{$modularTileBlock}__content {
        padding-right: 1rem;
        padding-left: 1rem;
        @include min-lg {
          padding-right: 3rem;
          padding-left: 3rem;
        }
      }
      .container {
        @include min-md {
          max-width: 60rem;
        }
      }
      .accordion-toggle {
        width: 100%;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
    }
    &__legal-documents {
      margin-top: 4rem;
    }
  }
  .firefox {
    .profile {
      &__tile {
        @include min-md {
          margin: 3.4rem auto;
        }
      }
    }
  }
</style>
