<template>
  <base-table>
    <caption class="tracking-table__caption">
      {{ name }}
    </caption>
    <thead>
      <base-table-header
        :data-testid="`${dataTestid}-table-header`"
        :items="headings"
      />
    </thead>
    <tbody>
      <base-table-row
        v-for="(item, i) in items"
        :key="i"
      >
        <base-table-element
          v-for="(element, j) in item.columns"
          :key="j"
        >
          {{ element }}
          <button
            v-if="item.display_title"
            class="legal-documents-list__link"
            :data-testid="`${dataTestid}-show-document`"
            @click="showLegalDocument(item)"
          >
            {{ item.display_title }}
          </button>
        </base-table-element>
        <base-table-element
          v-if="hasEditButton && item.columns.length > 0"
        >
          <base-anchor
            data-testid="tracking-details-table-edit"
            class="margin-right"
            @click="onEdit(item)"
          >
            {{ $t('Edit') }}
          </base-anchor>
          <base-anchor
            data-testid="tracking-details-table-delete"
            @click="onDelete(item)"
          >
            {{ $t('Delete') }}
          </base-anchor>
        </base-table-element>
      </base-table-row>
    </tbody>
  </base-table>
</template>

<script>
  import { BaseAnchor } from '@/components/base'
  import BaseTable from '@/components/base/table/BaseTable'
  import BaseTableElement from '@/components/base/table/element/BaseTableElement'
  import BaseTableHeader from '@/components/base/table/header/BaseTableHeader'
  import BaseTableRow from '@/components/base/table/row/BaseTableRow'

  import testid from '@/mixins/testid/testid'

  export default {
    components: {
      BaseTable,
      BaseTableElement,
      BaseTableHeader,
      BaseTableRow,
      BaseAnchor,
    },

    mixins: [
      testid(),
    ],

    props: {
      headings: {
        type: Array,
        default: () => [],
      },
      items: {
        type: Array,
        default: () => [],
      },
      name: {
        type: String,
        default: '',
      },
      hasEditButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      isEditEmit: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    methods: {
      onEdit(item) {
        this.$emit('edit', item.columns[0])
        this.$emit('editBy', item)
      },
      onDelete(item) {
        this.$emit('delete', item.columns[0])
        this.$emit('deleteBy', item)
      },
    },
  }
</script>

<style lang="scss">
  .margin-right {
    margin-right: 1rem;
  }
  .tracking-table {
    &__caption {
      margin-bottom: 1rem;
    }
  }
</style>
